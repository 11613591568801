// import '@babel/polyfill';
// import 'regenerator-runtime/runtime';
import { displayMap, displayLocSearch, displayLocSearchAndReturnRelaysList } from './mapbox.js';
// import H from 'here-js-api/scripts/mapsjs-core';
// import H from '@here/maps-api-for-javascript';

// import { displayHereMap } from './here';
import { login, logout, forgotPassword, resetPassword, signup, activate, sendNewCode, addEmployee, deleteEmployee, updateUserWithRelay } from './login.js';
import { sendEmail } from './contact.js';
import { updateSettings, toggleFavorites } from './settings.js';
// import { getRelaysWithin } from './relays.js';
import { updateShop, createShop, creditBalance, addSize, removeSize, addKey, removeKey } from './shops.js';
import { updateRelay, createRelay, createMyRelay, addHours, removeHours, changeHours, addExclDay, removeExclDay, removeMyRelay } from './relays.js';
import { getPacket, getPacketPaths, createPacket, updatePacketSize, validatePacket, checkInPacket, checkOutPacket } from './packets.js';
import { simulatePackets, simulateDeliveries, getTransactions, generateOrders, generatePurchases, createPayments, computePrices, toggleRelays, changeParentRels } from './admin.js';
import { searchDocs, createDelivery, checkNewDelivery, createProDelivery, endDeliveryScan, declareMissingPacket } from './deliveries.js';
import { pay, payPacket, createPaymentIntent, createStripeAccount, getStripeTransactions, createStripePayout, getStripePublicKey, getPaidPacketLabel, changePlan } from './payment.js';
import { acceptCg } from './cgs.js';
import { showAlert, showModal, hideModal } from './alerts.js';
import { generateFormAndSubmit, minutesToHm, convertTZ, validFileType, setCookie, getCookie, delay, checkRequiredFields, toBase64, countdown } from './utils.js';
import { displayCamStream } from './scan.js';
import { displayDp, displayAndReturnDp } from './datepicker.js';

const xsrfToken = localStorage.getItem('xsrfToken');

// // DOM ELEMENTS
const body = document.querySelector('body');
const themeSwitchBtn = document.querySelector('#switch input');
const darkDefaut = window.matchMedia("(prefers-color-scheme: dark)");
const mapBox = document.getElementById('map');
const mapHere = document.getElementById('mapContainer');
const eyes = document.querySelectorAll('.eye');
const loginForm = document.querySelector('.form--login');
const signupForm = document.querySelector('.form--signup')
const activationForm = document.querySelector('.form--activation')
const pickupCodeForm = document.querySelector('.form--pickupCode')
const cgForm = document.querySelector('.form--cg')
const forgotPasswordForm = document.querySelector('.form-forgot-pwd');
const resetPasswordForm = document.querySelector('.form-reset-pwd');
const logOutBtn = document.querySelector('.nav__el--logout');
const userDataForm = document.querySelector('.form-user-data');
const photo = document.getElementById('photo');
const userPasswordForm = document.querySelector('.form-user-password');
const addEmployeeForm = document.querySelector('.form-add-employee');
const deleteEmployeeBtn = document.querySelectorAll('.delete-employee');
const shopForm = document.querySelector('.form-shop');
const availSlotSelect = document.querySelectorAll('.availSlot');
const relForm = document.querySelector('.form-rel');
const removeRelBtn = document.querySelectorAll('.removeRel');
const startSendForm = document.getElementById('startSendForm');
const sendPacketForm = document.querySelector('.form--send-packet');
const payPacketForm = document.querySelector('.form--pay-packet');
const searchPaths = document.querySelector('.form--search-paths');
const searchDels = document.querySelector('.form--search-dels');
const searchByFavs = document.querySelector('.form--searchByFavs');
const favoritesIcon = document.querySelectorAll('.fav');
const toggleButtons = document.querySelectorAll('.fav');
const postLinks = document.querySelectorAll('.postLink');
const canvasElement = document.getElementById("canvas");
const urlParams = new URLSearchParams(window.location.search);
const deliveryForm = document.querySelector('.form--post-delivery');
const modifyPacketForm = document.getElementById('formPacketModify');
const createPacketForm = document.getElementById('formPacketCreate');
const validatePacketBtn = document.querySelectorAll('.validate');
const checkInPacketBtn = document.querySelectorAll('.checkIn');
const getLabelBtn = document.querySelectorAll('.getLabel');
const getInvoiceBtn = document.querySelectorAll('.getInvoice');
const getDocsBtn = document.querySelectorAll('.getDocs');
const shopLinkBtn = document.querySelectorAll('.shopLink');
const relLinkBtn = document.querySelectorAll('.relLink');
const addSizeBtn = document.querySelectorAll('.addSize');
const removeSizeBtn = document.querySelectorAll('.removeSize');
const addKeyBtn = document.querySelectorAll('.addKey');
const removeKeyBtn = document.querySelectorAll('.removeKey');
const addHoursBtn = document.querySelectorAll('.addHours');
const removeHoursBtn = document.querySelectorAll('.removeHours');
const removeExclDayBtn = document.querySelectorAll('.removeExclDays');
const defaultSizes = document.querySelectorAll('[name="size"]');
const selectCRs = document.getElementById('selectCRs');
const deliverPathsForm = document.querySelector('.form--deliverPaths');
const endDelScan = document.getElementById('endDelScan');
const declareMissing = document.getElementById('declareMissing');
const playSign = document.querySelector('.play-sign');
const count = document.getElementById('count');
const dropBtn = document.querySelectorAll('.dropbtn');
const relaySelect = document.getElementById('relaySelect');
const shopSelect = document.getElementById('shopSelect');
const createStripeAccountBtn = document.getElementById('createStripeAccount');
const viewNextTransactionsBtn = document.getElementById('viewNextTransactions');
const requestPayoutBtn = document.getElementById('requestPayout');

// Admin Vues Elements
const simulatePacketsForm = document.getElementById('formSimulatePackets');
const simulateDeliveriesForm = document.getElementById('formSimulateDeliveries');
const generateOrdersForm = document.getElementById('formGenerateOrders');
const generatePurchasesForm = document.getElementById('formGeneratePurchases');
const createPaymentsForm = document.getElementById('formCreatePayments');
const getTransactionsForm = document.getElementById('formGetTransactions');
const dashboardFilterForm = document.getElementById('formDashboard');
const firstPaymentForm = document.getElementById('formFirstPayment');
const changePlanForm = document.getElementById('formChangePlan');
const relayActionsForm = document.getElementById('formRelayActions');
const relaySelectBox = document.querySelectorAll('.relaySelectBox');
const packetsActionsForm = document.getElementById('formPacketsActions');
const packetSelectBox = document.querySelectorAll('.packetSelectBox');
const delsChart = document.getElementById('delsChart');
const computePricesBtn = document.querySelector('.computePrices');

// get params if existing
if (urlParams.has('err'))
  if (urlParams.get('err') === 'inscription') showAlert('error', 'Vous devez d\'abord créer un compte avant de pouvoir effectuer une livraison.');

window.addEventListener('load', (event) => {
  delay(500).then(() => document.getElementById('spinner').remove());
});

// Toggle theme light <-> dark
if (themeSwitchBtn) {
  const theme = getCookie('theme');
  if (theme) {
    themeSwitchBtn.checked = (theme === 'dark');
    body.classList.add((theme === 'dark') ? "dark" : 'light');
  } else {
    themeSwitchBtn.checked = darkDefaut.matches;
    body.classList.add((darkDefaut.matches) ? "dark" : 'light');
  }
  themeSwitchBtn.addEventListener('change', function (e) {
    body.classList.add(themeSwitchBtn.checked ? "dark" : "light");
    body.classList.remove(themeSwitchBtn.checked ? "light" : "dark");
    setCookie('theme', themeSwitchBtn.checked ? "dark" : "light", 365);
  });
}

if (playSign) {
  const video = document.getElementById("video");
  const img1 = document.getElementById("img1");
  const img2 = document.getElementById("img2");
  const img3 = document.getElementById("img3");
  playSign.addEventListener('click', e => {
    video.style.display = "block";
    video.play();
    const stopVideo = (vid, targetEl) => {
      if (vid.style.display === "block") {
        if (!targetEl || (targetEl !== vid && targetEl !== playSign && targetEl.parentNode !== playSign)) {
          vid.pause();
          vid.currentTime = 0;
          vid.style.display = "none";
        }
      }
    }
    document.addEventListener("click", (evt) => stopVideo(video, evt.target));
    document.onkeyup = function (event) {
      if (event.key === "Escape") {
        stopVideo(video);
      }
    };
  });
  playSign.addEventListener('mouseenter', e => {
    img1.classList.add('rotate');
    img2.classList.add('rotate');
    img3.classList.add('rotate');
  });
  playSign.addEventListener('mouseout', e => {
    img1.classList.remove('rotate');
    img2.classList.remove('rotate');
    img3.classList.remove('rotate');
  });
}

if (count) {
  const textarea = document.querySelector('textarea');
  textarea.onkeyup = (e) => {
    count.innerHTML = (500 - e.target.value.length) + " caractères restants";
  };
}


///////////////////////////////////////////////////////
/////////////////// MAPS MANAGEMENT ///////////////////
///////////////////////////////////////////////////////

// DELEGATION
const locations = (mapBox) ? JSON.parse(mapBox.dataset.locations) : '';
const map = (mapBox && locations !== '') ? (mapBox.dataset.rels) ? displayMap(locations, JSON.parse(mapBox.dataset.rels)) : displayMap(locations) : '';


if (mapHere) {
  const locs = JSON.parse(mapHere.dataset.locations);
  // const startEndLocs = JSON.parse(mapHere.dataset.startEndLocs);
  import('./here.js')
    .then(module => {
      module.displayHereMap(locs);
    })
    .catch(error => console.log(error));
}


///////////////////////////////////////////////////////
///////////////////  COMMON ASSETS ////////////////////
///////////////////////////////////////////////////////

if (dropBtn)
  dropBtn.forEach(el => {
    el.addEventListener('click', async evt => {
      evt.preventDefault();
      let content = el.parentNode.querySelector('.dropdown-content');
      if (window.getComputedStyle(content).display === "none") {
        let contents = document.querySelectorAll('.dropdown-content');
        // content.style.display = (window.getComputedStyle(content).display === "none") ? 'block' : 'none';
        contents.forEach(e => { e.style.display = 'none'; });
        content.style.display = 'block';
      } else {
        content.style.display = 'none';
      }
    });
  });


///////////////////////////////////////////////////////
///////////////// DELIVERY MANAGEMENT /////////////////
///////////////////////////////////////////////////////

if (deliveryForm) {

  // slider for trunck volume adjustment
  const sliderVol = document.getElementById('sliderVol');
  const outputVol = document.getElementById('outputVol');
  sliderVol.oninput = function () {
    outputVol.innerHTML = `${this.value} L`;
  }

  // // slider for price adjustment
  // const sliderPrice = document.getElementById('sliderPrice');
  // const outputPrice = document.getElementById('outputPrice');
  // sliderPrice.oninput = function () {
  //   outputPrice.innerHTML = `${this.value}%`;
  // }

  // Calendar setup and display
  const dp = document.getElementById('dp');
  const hours = JSON.parse(dp.dataset.hours);

  var excWkDays = [];
  for (let i = 0; i < hours.length; i++) {
    if (hours[i].length === 0) excWkDays.push(i);
    else {
      if (hours[i][0][1] < hours[i][0][0] && hours[i][1][1] < hours[i][1][0]) excWkDays.push(i);
      if (hours[i][1][1] < hours[i][1][0]) hours[i].splice(1, 1);
      if (hours[i][0][1] < hours[i][0][0]) hours[i].splice(0, 1);
    }
  }
  const startExcDays = JSON.parse(dp.dataset.startexcdays).map(el => convertTZ(el).toString());
  const endExcDays = JSON.parse(dp.dataset.endexcdays).map(el => convertTZ(el).toString());
  // const startExcDays = JSON.parse(dp.dataset.startexcdays).map(el => new Date(el.slice(0, -2)).toString());
  // const endExcDays = JSON.parse(dp.dataset.endexcdays).map(el => new Date(el.slice(0, -2)).toString());
  const excDays = [...endExcDays, ...startExcDays];

  const minDate = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000);
  const maxDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
  const startDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);

  const s1 = document.getElementById('slot1');
  const s2 = document.getElementById('slot2');
  const slot1 = document.getElementById('label1');
  const slot2 = document.getElementById('label2');
  const lab1Text = document.getElementById('lab1Text');
  const lab2Text = document.getElementById('lab2Text');
  var dateVal = document.getElementById('date');

  displayDp(dp, {
    onSelect: instance => {
      // Show 1 or 2 slots depending on selected date
      let d = instance.dateSelected;
      console.log(d);
      dateVal.value = instance.dateSelected;
      s1.checked = false;
      s2.checked = false;
      if (!d) {
        if (!slot1.classList.contains('inactive')) slot1.classList.add('inactive');
        if (!slot2.classList.contains('inactive')) slot2.classList.add('inactive');
      } else {
        let i = new Date(instance.dateSelected).getDay();
        if (hours[i].length === 2) {
          if (slot1.classList.contains('inactive')) slot1.classList.remove('inactive'); lab1Text.innerText = `Entre ${minutesToHm(hours[i][0][0])} et ${minutesToHm(hours[i][0][1])}`;
          if (slot2.classList.contains('inactive')) slot2.classList.remove('inactive'); lab2Text.innerText = `Entre ${minutesToHm(hours[i][1][0])} et ${minutesToHm(hours[i][1][1])}`;
        } else if (hours[i][0][0] < 780) {
          if (slot1.classList.contains('inactive')) slot1.classList.remove('inactive'); lab1Text.innerText = `Entre ${minutesToHm(hours[i][0][0])} et ${minutesToHm(hours[i][0][1])}`; s1.checked = true;
          if (!slot2.classList.contains('inactive')) slot2.classList.add('inactive');
        } else {
          if (!slot1.classList.contains('inactive')) slot1.classList.add('inactive');
          if (slot2.classList.contains('inactive')) slot2.classList.remove('inactive'); lab2Text.innerText = `Entre ${minutesToHm(hours[i][0][0])} et ${minutesToHm(hours[i][0][1])}`; s2.checked = true;
        }
      }
    },
    startDay: 1,
    startDate: startDate,
    alwaysShow: true,
    customDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    showAllDates: true,
    minDate: minDate,
    maxDate: maxDate,
    disableYearOverlay: true,
    disabler: date => (excDays.includes(date.toString()) || excWkDays.includes(date.getDay()))
  });


  deliveryForm.addEventListener('submit', async e => {
    e.preventDefault();
    const
      path = document.getElementById('pathId').value,
      carrier = document.getElementById('userId').value,
      distance = document.getElementById('pathDistance').value,
      date = document.getElementById('date').value,
      slot = (document.getElementById('slot2').checked) ? 2 : ((document.getElementById('slot1').checked) ? 1 : 0),
      initialTrunkVol = document.getElementById('sliderVol').value,
      leftTrunkVol = document.getElementById('sliderVol').value,
      // gainPercentage = document.getElementById('sliderPrice').value / 100;
      gainPercentage = 1;

    if (!date || !slot || slot === 0) {
      showAlert('error', 'Veuillez renseigner une date et un créneau pour la livraison.');
    } else {
      const data = {
        path,
        carrier,
        status: 'booked',
        date,
        slot,
        initialTrunkVol,
        leftTrunkVol,
        distance,
        gainPercentage
      };

      if (await checkNewDelivery(data)) {
        createDelivery(data);
      }
    }
  });
}


///////////////////////////////////////////////////////
////////////////// PACKET MANAGEMENT //////////////////
///////////////////////////////////////////////////////
if (defaultSizes)
  defaultSizes.forEach(el => {
    el.addEventListener('click', async e => {
      var weight = document.getElementById('weight');
      var length = document.getElementById('length');
      var width = document.getElementById('width');
      var height = document.getElementById('height');
      weight.value = el.dataset.weight;
      length.value = el.dataset.length;
      width.value = el.dataset.width;
      height.value = el.dataset.height;
    });
  });

if (modifyPacketForm)
  modifyPacketForm.addEventListener('submit', async e => {
    e.preventDefault();
    const id = document.getElementById('id').value;
    const data = {
      weight: document.getElementById('weight').value,
      length: document.getElementById('length').value,
      width: document.getElementById('width').value,
      height: document.getElementById('height').value
    };
    await updatePacketSize(data, id);
  });

/////////// SIMULATION PURPOSE ONLY //////////////////
if (createPacketForm) {

  // Calendar setup and display
  const dp = document.getElementById('dp');
  const date = document.getElementById('date');

  displayDp(dp, {
    onSelect: instance => {
      date.value = instance.dateSelected;
    },
    startDay: 1,
    startDate: new Date(Date.now()),
    alwaysShow: true,
    customDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    showAllDates: true,
    disableYearOverlay: true,
  });

  // geocoder
  const results = document.getElementById('latlng');
  displayLocSearchAndReturnRelaysList('#geocoder', results);

  createPacketForm.addEventListener('submit', async e => {
    e.preventDefault();
    document.querySelector('.btn--save').textContent = 'En cours...';
    var shopData = document.getElementById('startRel').value;
    var clientShop = shopData.slice(1, -1).split(',')[0];
    var startRel = shopData.slice(1, -1).split(',')[1];

    // // Modification suite à ajout du système de formats de colis prédéfinis au niveau de la boutique (plus besoin de spécifier les dimensions)
    // var defaultPacketSize = clientShop.sizes[clientShop.defaultSize];
    // var weight = defaultPacketSize.maxWeight || document.getElementById('weight').value;
    // var length = defaultPacketSize.l || document.getElementById('length').value;
    // var width = defaultPacketSize.w || document.getElementById('width').value;
    // var height = defaultPacketSize.h || document.getElementById('height').value;
    var weight = (document.getElementById('weight')) ? document.getElementById('weight').value : 0;
    var length = (document.getElementById('length')) ? document.getElementById('length').value : 0;
    var width = (document.getElementById('width')) ? document.getElementById('width').value : 0;
    var height = (document.getElementById('height')) ? document.getElementById('height').value : 0;

    var data = {
      customerName: document.getElementById('customerName').value,
      customerFirstName: document.getElementById('customerFirstName').value,
      customerEmail: document.getElementById('customerEmail').value,
      weight,
      length,
      width,
      height,
      clientShop,
      startRel,
      availDate: document.getElementById('date').value,
      availSlot: document.getElementById('slot').value,
      endRel: (document.querySelector('input[name="endRel"]:checked')) ? document.querySelector('input[name="endRel"]:checked').value : '',
      chosenPath: (document.querySelector('input[name="chosenPath"]:checked')) ? document.querySelector('input[name="chosenPath"]:checked').value * 1 : ''
    };

    // if (data.length < data.width || data.width < data.height) {
    //   showAlert('error', 'La longueur ne peut pas être inférieure à la largeur et la largeur ne peut pas être inférieure à la hauteur.');
    // } else if (data.date === '' || data.endRel === '') {
    if (data.date === '' || data.endRel === '') {
      showAlert('error', 'Veuillez renseigner une date et sélectionner un relais d\'arrivée avant de continuer.');
    } else {
      if (data.chosenPath === '') {
        const paths = await getPacketPaths(data);
        if (paths) {
          document.querySelector('.btn--save').textContent = 'Générer le colis';
          var container = document.getElementById('paths');
          var n = 0;
          container.innerHTML = '';

          paths.forEach(e => {
            var radiobox = document.createElement('input');
            radiobox.type = 'radio';
            radiobox.id = 'path' + n;
            radiobox.value = n;
            radiobox.name = 'chosenPath';
            radiobox.style = 'display: block; float: left;'

            var div = document.createElement('div');
            // div.classList.add('path');
            div.style = 'border: 1px solid; border-radius: 5px; margin: 5px 0; padding: 5px;'
            var label = document.createElement('label')
            label.htmlFor = 'path' + n;

            var span = document.createElement('span');
            var span2 = document.createElement('span');
            var span3 = document.createElement('span');
            var span4 = document.createElement('span');
            label.appendChild(span);
            label.appendChild(document.createElement('br'));
            label.appendChild(span2);
            label.appendChild(document.createElement('br'));
            label.appendChild(span3);
            label.appendChild(document.createElement('br'));
            label.appendChild(span4);
            const dels = e.deliveries.map(el => el.carrier.firstName);
            var text = 'L\'ensemble du trajet est assuré par Carcolis.';
            if (dels.length > 0) {
              text = `${dels[0]}`;
              if (dels.length > 1) {
                for (var i = 1; i < dels.length; i++) {
                  text += ' et ' + dels[i];
                }
              }
            }
            var text2 = `assure${(dels.length > 1) ? 'nt' : ''} ${e.ratioPart}% du trajet total du colis.`;
            var text3 = `Coût du transport: ${e.price.priceTaxIncluded.toFixed(2)} €`;
            var text4 = `Livraison prévue le ${new Date(e.date).toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })}`;
            var description = document.createTextNode(text);
            var description2 = document.createTextNode(text2);
            var description3 = document.createTextNode(text3);
            var description4 = document.createTextNode(text4);
            span.appendChild(description);
            if (e.ratioPart !== 0) span2.appendChild(description2);
            span3.appendChild(description3);
            span4.appendChild(description4);

            var newline = document.createElement('br');

            container.appendChild(div);
            div.appendChild(radiobox);
            div.appendChild(label);
            div.appendChild(newline);

            n++;
          });
        } else {
          showAlert('error', 'Un problème a eu lieu. Il est probable que les dimensions du colis sont hors limite.');
        }
      } else {
        await createPacket(data);
      }
    }
  });
}

if (validatePacketBtn)
  validatePacketBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      // console.log(user.id);
      var id = el.value;
      await validatePacket(id);
      window.location.reload();
    });
  });

if (checkInPacketBtn)
  checkInPacketBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      var id = el.value;
      await checkInPacket(id);
    });
  });

if (getLabelBtn)
  getLabelBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      var id = el.value;
      window.open(`/mes-colis/${id}/etiquette`);
      // await checkInPacket(id);
    });
  });

if (getInvoiceBtn)
  getInvoiceBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      var id = el.value;
      window.open(`/mes-consos/${id}/facture`);
    });
  });

if (getDocsBtn)
  getDocsBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      var id = el.value;
      for (let doc of ['idRecto', 'idVerso', 'kbis', 'rib', 'erp']) {
        window.open(`/admin/relais/${id}/documents/${doc}`);
      }
    });
  });

if (pickupCodeForm) {
  const inputs = document.querySelectorAll("input[type='number']");
  inputs.forEach(function (input, index) {
    input.addEventListener("input", event => {
      const e = event.target;
      const inputValue = e.value;
      if (isNaN(inputValue)) {
        event.preventDefault();
      }
      if (input.value.length === input.maxLength) {
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        } else document.getElementById('submitPickupCode').focus();
      }
    });
    input.addEventListener("focus", evt => {
      if (evt.target.value !== "") {
        for (let input of inputs) {
          input.value = "";
        }
        inputs[0].focus();
      }
    });
  });
  pickupCodeForm.addEventListener("submit", e => {
    e.preventDefault();
    let code = "";
    for (let input of inputs) {
      code += input.value;
    }
    checkOutPacket([document.getElementById('id').value, code]);
  });
}


///////////////////////////////////////////////////////
/////////////////// SHOP MANAGEMENT ///////////////////
///////////////////////////////////////////////////////
if (shopForm) {

  const id = document.getElementById('id').value;
  const results = document.getElementById('latlng');
  displayLocSearch('#geocoder', results);
  var shippingRel = (document.getElementById('shippingRel')) ? document.getElementById('shippingRel').value : null;
  // var slots = (document.getElementById('shippingRel')) ? document.getElementById('shippingRel').value : null;

  shopForm.addEventListener('submit', async e => {

    e.preventDefault();

    const name = document.getElementById('name').value;
    const siret = document.getElementById('siret').value;
    const domain = document.getElementById('domain').value;

    var location = (id && document.getElementById('address')) ? {
      type: "Point",
      coordinates: [
        document.getElementById('lng').value * 1,
        document.getElementById('lat').value * 1,
      ],
      address: document.getElementById('address').value
    } : null;

    if (results.innerText !== '') {
      const loc = JSON.parse(results.innerText);
      location = {
        type: "Point",
        coordinates: loc.center,
        address: loc.place_name
      };
    }

    if (!location) return showAlert('error', 'Veuillez renseigner l\'adresse physique de votre boutique pour continuer.');

    var data = {
      name,
      siret,
      domain,
      location
    };

    if (id) {
      var rels = document.getElementsByName('rel');
      var newShippingRel = '';
      if (rels.length > 0) {
        for (var i = 0; i < rels.length; i++) {
          if (rels[i].checked) {
            newShippingRel = rels[i].value;
          }
        }
      }
      if (document.querySelector('.tag-red') && (rels.length === 0 || shippingRel === newShippingRel)) showAlert('error', 'Mauvais paramétrage des délais d\'expédition');
      else {
        var shipPeriod = [];
        shippingRel = newShippingRel;
        // if (shippingRel === '' || (document.querySelector('.beware'))) return showAlert('error', 'Ce relais n\'est pas paramétré correctement. Veuillez en choisir un nouveau SVP.');
        data = {
          ...data,
          shippingRel,
          location,
          defaultSize: (document.querySelector('input[name="defaultSize"]:checked')) ? document.querySelector('input[name="defaultSize"]:checked').value * 1 : '',
        };
        if (document.querySelector('select.availSlot')) {
          for (var i = 0; i < 7; i++) {
            var j = (i === 6) ? 0 : i + 1;
            for (var k = 1; k < 3; k++) {
              shipPeriod.push({
                orderedDay: j,
                orderedSlot: k,
                orderedBefore: document.querySelector(`select.availSlot[data-type="orderedBefore"][data-slot="${j}-${k}"]`).value * 1,
                shippedDay: JSON.parse(document.querySelector(`select.availSlot[data-type="shippedSlot"][data-slot="${j}-${k}"]`).value)[0],
                shippedSlot: JSON.parse(document.querySelector(`select.availSlot[data-type="shippedSlot"][data-slot="${j}-${k}"]`).value)[1]
              });
            }
          }
          data = {
            ...data,
            shipPeriod
          };

        }
        data.step = (id) ? (shippingRel) ? (shipPeriod.length > 0) ? (data.defaultSize !== '') ? (removeKeyBtn.length > 0) ? 6 : 5 : 4 : 3 : 2 : 1;

        document.querySelector('.btn--save').textContent = 'En cours...';
        await updateShop(data, id);

        setTimeout(function () {
          window.location.reload();
        }, 2500);
      }
    } else {
      await createShop(data);
    }
  });
}

if (shopLinkBtn)
  shopLinkBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      shopLinkBtn.forEach(link => { link.classList.remove('selected') });
      el.classList.add('selected');
      for (var step = 1; step < 6; step++) {
        document.getElementById('shopScreen' + step).style.display = 'none';
      }
      document.getElementById('shopScreen' + el.dataset.step).style.display = 'block';
    });
  });

if (availSlotSelect)
  availSlotSelect.forEach(el => {
    el.addEventListener('change', e => {
      e.preventDefault();
      const slot = el.getAttribute('data-slot');
      const od = slot.split('-')[0] * 1;
      const orderedBefore = document.querySelector(`select.availSlot[data-type="orderedBefore"][data-slot="${slot}"]`).value * 1;
      const shippedSlot = document.querySelector(`select.availSlot[data-type="shippedSlot"][data-slot="${slot}"]`).value;
      const slots = JSON.parse(document.getElementById('slots').value);
      const tag = document.querySelector(`span.tag[data-slot="${slot}"]`);

      const sd = JSON.parse(shippedSlot)[0];
      const sdArrPos = (sd === 0) ? 6 : sd - 1;
      const ssArrPos = JSON.parse(shippedSlot)[1] - 1;
      var t = slots[sdArrPos][ssArrPos][1];
      var h = Math.floor(t / 60);
      var m = t % (h * 60);
      m = (m === 0) ? '00' : m;
      if (slots[sdArrPos][ssArrPos].length === 0) {
        tag.innerHTML = 'Impossible: Relais fermé';
        tag.className = "tag tag-red";
      }
      else if (od === sd && t < orderedBefore + 15) {
        tag.innerHTML = `Impossible: Relais ferme à ${h}:${m}`;
        tag.className = "tag tag-red";
      }
      else if (od === sd && t < orderedBefore + 120) {
        tag.innerHTML = `Attention: Relais ferme à ${h}:${m}`;
        tag.className = "tag tag-orange";
      }
      else {
        tag.innerHTML = `Ouvert: déposer avant ${h}:${m}`;
        tag.className = "tag tag-dark-green";
      }

    });
  });

if (addHoursBtn)
  addHoursBtn.forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault();
      addHours(el);
    });
  });

if (removeHoursBtn)
  removeHoursBtn.forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault();
      removeHours(el);
    });
  });

if (removeExclDayBtn)
  removeExclDayBtn.forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault();
      removeExclDay(el);
    });
  });

if (addSizeBtn)
  addSizeBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      var sizeNum = document.getElementById('sizeNum').value;
      if (sizeNum > 9) showAlert('error', 'Vous ne pouvez définir que 10 formats maximum.');
      else {
        var id = document.getElementById('id').value;
        var l = document.getElementById('l').value * 1;
        var w = document.getElementById('w').value * 1;
        var h = document.getElementById('h').value * 1;
        if (l > 80) showAlert('error', 'La longueur ne peut pas dépasser 80 cm.');
        else if ((l + w + h) > 170) showAlert('error', 'La somme des dimensions ne peut pas excéder 170 cm.');
        else if (l < w) showAlert('error', 'La longueur doit être supérieure ou égale à la largeur.');
        else if (w < h) showAlert('error', 'La largeur doit être supérieure ou égale à la hauteur.');
        else {
          var data = { l, w, h };
          await addSize(data, id);
          window.location.reload();
        }
      }
    });
  });

if (removeSizeBtn)
  removeSizeBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      var data = { sizeNum: el.getAttribute('data-num') };
      var id = document.getElementById('id').value;
      await removeSize(data, id);
      window.location.reload();
    });
  });

if (addKeyBtn)
  addKeyBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      var id = document.getElementById('id').value;
      showModal(
        'success',
        'Confirmer la génération d\'une nouvelle clé API ?',
        [
          {
            function: async () => {
              await addKey(id);
              window.location.reload();
            },
            text: 'Confirmer'
          },
          {
            function: hideModal,
            text: 'Annuler'
          }
        ]
      );
    });
  });

if (removeKeyBtn)
  removeKeyBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      var data = { apiKey: el.getAttribute('data-num') };
      var id = document.getElementById('id').value;
      showModal(
        'success',
        'Confirmer la suppression de cette clé API ?',
        [
          {
            function: async () => {
              await removeKey(data, id);
              window.location.reload();
            },
            text: 'Confirmer'
          },
          {
            function: hideModal,
            text: 'Annuler'
          }
        ]
      );
    });
  });


///////////////////////////////////////////////////////
/////////////////// SHOP DASHBOARD ////////////////////
///////////////////////////////////////////////////////
if (dashboardFilterForm) {

  const join = (t, a, s) => {
    function format(m) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
    }
    return a.map(format).join(s);
  };
  var options = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }];

  // Calendar setup and display
  var startDate = new Date(document.getElementById('startDate').value);
  var endDate = new Date(document.getElementById('endDate').value);
  var dateStart = document.getElementById('start');
  var dateEnd = document.getElementById('end');

  displayDp(dateStart, {
    dateSelected: startDate,
    onSelect: instance => {
      document.getElementById('startDate').value = instance.dateSelected;
      dateStart.value = join(instance.dateSelected, options, '-');
    },
    startDay: 1,
    startDate: new Date(Date.now()),
    alwaysShow: false,
    customDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    showAllDates: true,
    disableYearOverlay: true,
  });

  displayDp(dateEnd, {
    dateSelected: endDate,
    onSelect: instance => {
      document.getElementById('endDate').value = instance.dateSelected;
      dateEnd.value = join(instance.dateSelected, options, '-');
    },
    startDay: 1,
    startDate: new Date(Date.now()),
    alwaysShow: false,
    customDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    showAllDates: true,
    disableYearOverlay: true,
  });

  dateStart.value = join(startDate, options, '-');
  dateEnd.value = join(endDate, options, '-');

  dashboardFilterForm.addEventListener('submit', async e => {
    e.preventDefault();
    var shop = (document.getElementById('shop')) ? document.getElementById('shop').value : false;

    document.querySelector('.btn--save').textContent = 'En cours...';

    if (new Date(document.getElementById('endDate').value).valueOf() < new Date(document.getElementById('startDate').value).valueOf()) {
      showAlert('error', 'Veuillez sélectionner une date de fin postérieure à la date de début.');
      document.querySelector('.btn--save').textContent = 'Afficher les données';
    } else if (new Date(document.getElementById('endDate').value).valueOf() > new Date(document.getElementById('startDate').value).valueOf() + 92 * 24 * 60 * 60 * 1000) {
      showAlert('error', 'Veuillez sélectionner une période de 3 mois maximum.');
      document.querySelector('.btn--save').textContent = 'Afficher les données';
    } else {
      // window.location.href = `/admin/dashboard?start=${dateStart.value}&end=${dateEnd.value}`;
      window.location.search = `?${(shop) ? 'shop=' + shop + '&' : ''}start=${dateStart.value}&end=${dateEnd.value}`;
    }
  });
}

if (changePlanForm) {

  const join = (t, a, s) => {
    function format(m) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
    }
    return a.map(format).join(s);
  };
  var plan = document.getElementById('plan');
  var activePlanId = document.getElementById('activePlanId').value;
  var changePlanBtn = document.getElementById('changePlanBtn');
  var changeDateContainer = document.getElementById('changeDateContainer');
  plan.addEventListener('change', e => {
    e.preventDefault();
    for (let p of [0, 1, 2, 3, 4]) {
      document.getElementById('planDetails' + p).style.display = 'none';
    }
    if (plan.value !== '-1') {
      document.getElementById('planDetails' + plan.value).style.display = 'block';
      changePlanBtn.disabled = false;
      changeDateContainer.style.display = 'block';
    } else {
      changePlanBtn.disabled = true;
      changeDateContainer.style.display = 'none';
    }
  });

  var options = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }];

  // Calendar setup and display
  var hiddenChangeDate = new Date(document.getElementById('hiddenChangeDate').value);
  var changeDate = document.getElementById('changeDate');

  displayDp(changeDate, {
    dateSelected: hiddenChangeDate,
    onSelect: instance => {
      changeDate.value = join(instance.dateSelected, options, '-');
    },
    minDate: hiddenChangeDate,
    startDay: 1,
    startDate: new Date(Date.now()),
    alwaysShow: false,
    customDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    showAllDates: true,
    disableYearOverlay: true,
  });

  changeDate.value = join(hiddenChangeDate, options, '-');

  changePlanForm.addEventListener('submit', async e => {
    e.preventDefault();
    document.querySelector('#formChangePlan .btn--save').textContent = 'En cours...';
    var data = {
      changeDate: new Date(changeDate.value.slice(6, 10), changeDate.value.slice(3, 5) - 1, changeDate.value.slice(0, 2)),
      plan: plan.value,
      shop: document.getElementById('hiddenShop').value
    };
    await changePlan(data);
  });
}

if (firstPaymentForm)
  firstPaymentForm.addEventListener('submit', async e => {
    e.preventDefault();
    var data = {
      shop: document.getElementById('hiddenShop').value
    };
    await pay(data);
  });

if (relayActionsForm && relaySelectBox) {
  var selectedRelsArray = JSON.parse(document.querySelector('#selectedRels .selectedRelsArray').value);
  var selectedRelsCount = document.getElementById('selectedRels').dataset.rels_count * 1;
  var toggleActiveBtn = document.querySelector('#selectedRels button.toggleActive');
  var toggleVirtualBtn = document.querySelector('#selectedRels button.toggleVirtual');
  var changeParentRelsBtn = document.querySelector('#selectedRels button.changeParentRels');
  var changeParentRelsForm = document.getElementById('changeParentRelsForm');

  relaySelectBox.forEach(el => {
    el.addEventListener('change', e => {
      if (el.checked) {
        selectedRelsCount++;
        selectedRelsArray.push(el.value);
      } else {
        selectedRelsCount--;
        let index = selectedRelsArray.indexOf(el.value);
        if (index > -1) { // only splice array when item is found
          selectedRelsArray.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      document.getElementById('selectedRels').dataset.rels = JSON.stringify(selectedRelsArray);
      document.getElementById('selectedRels').dataset.rels_count = selectedRelsCount;
      document.querySelector('#selectedRels span').innerText = selectedRelsCount + ' sélectionné' + ((selectedRelsCount < 2) ? '' : 's');

      document.querySelector('#selectedRels .dropbtn').disabled = (selectedRelsCount > 0) ? false : true;
    });
  });

  toggleActiveBtn.addEventListener('click', e => {
    e.preventDefault();
    toggleRelays(selectedRelsArray, 'active');
    setTimeout(function () {
      window.location.reload();
    }, 2500);
  });

  toggleVirtualBtn.addEventListener('click', e => {
    e.preventDefault();
    toggleRelays(selectedRelsArray, 'virtual');
    setTimeout(function () {
      window.location.reload();
    }, 2500);
  });

  changeParentRelsBtn.addEventListener('click', e => {
    e.preventDefault();
    document.getElementById('changeParentRelsForm').style.display = 'flex';
    document.querySelector('#selectedRels .dropdown-content').style.display = 'none';
  });

  changeParentRelsForm.addEventListener('submit', e => {
    e.preventDefault();
    let selectedParentRelsArray = Array.from(document.querySelectorAll('#changeParentRelsForm input[type="checkbox"]:checked')).map(e => e.value);
    changeParentRels(selectedRelsArray, selectedParentRelsArray);
    setTimeout(function () {
      window.location.reload();
    }, 2500);
  });
}

if (packetsActionsForm && packetSelectBox) {
  var selectedPacketsArray = JSON.parse(document.querySelector('#selectedPackets .selectedPacketsArray').value);
  var selectedPacketsCount = document.getElementById('selectedPackets').dataset.packets_count * 1;
  var downloadLabelsBtn = document.querySelector('#selectedPackets button.downloadLabels');

  packetSelectBox.forEach(el => {
    el.addEventListener('change', e => {
      if (el.checked) {
        selectedPacketsCount++;
        selectedPacketsArray.push(el.value);
      } else {
        selectedPacketsCount--;
        let index = selectedPacketsArray.indexOf(el.value);
        if (index > -1) { // only splice array when item is found
          selectedPacketsArray.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      document.getElementById('selectedPackets').dataset.rels = JSON.stringify(selectedPacketsArray);
      document.getElementById('selectedPackets').dataset.rels_count = selectedPacketsCount;
      document.querySelector('#selectedPackets span').innerText = selectedPacketsCount + ' sélectionné' + ((selectedPacketsCount < 2) ? '' : 's');

      document.querySelector('#selectedPackets .dropbtn').disabled = (selectedPacketsCount > 0) ? false : true;
    });
  });

  downloadLabelsBtn.addEventListener('click', e => {
    e.preventDefault();
    window.open(`/mes-colis/etiquettes/${JSON.stringify(selectedPacketsArray)}`);
  });
}


///////////////////////////////////////////////////////
/////////////////// STORE DASHBOARD ///////////////////
///////////////////////////////////////////////////////
if (relaySelect)
  relaySelect.addEventListener('change', e => {
    window.location.search = (relaySelect.value !== '') ? `?relay=${relaySelect.value}` : '';
  });


///////////////////////////////////////////////////////
/////////////////// USER MANAGEMENT ///////////////////
///////////////////////////////////////////////////////
if (signupForm) {

  const results = document.getElementById('latlng');
  const photos = document.getElementById('photos');
  var submitBtn = signupForm.querySelector('button.submit');
  var simulateBtn = document.getElementById('simulate');
  var addressErrorMessage = document.getElementById('addressOutputErrorMess');
  var fileErrorMessage = document.getElementById('fileOutputErrorMess');
  const pwd = document.getElementById('password');
  const pwdConfirm = document.getElementById('passwordConfirm');
  var pwdErrorMessage = document.getElementById('pwdOutputErrorMess');
  // var requiredFields = [...signupForm.querySelectorAll('input[required]')];

  if (results) {
    displayLocSearch('#geocoder', results);
    // requiredFields.push(results);
  }

  if (simulateBtn) simulateBtn.addEventListener('click', e => {
    e.preventDefault();
    var volStockage = document.getElementById('volStockage');
    var result = document.querySelector('.result');
    if (volStockage.value) {
      result.innerHTML = (volStockage.value * 0.3 * 20 * 0.35 * 25).toFixed(2);
    } else {
      volStockage.focus();
    }
  });

  // if (!checkRequiredFields(requiredFields)) submitBtn.disabled = true;
  // for (var field of requiredFields) {
  //   field.addEventListener('change', e => {
  //     if (checkRequiredFields(requiredFields)) submitBtn.disabled = false;
  //     else submitBtn.disabled = true;
  //   });
  // }

  if (pwd && pwdConfirm) {
    for (let f of [pwd, pwdConfirm]) {
      f.addEventListener('change', e => {
        if (pwd.value !== pwdConfirm.value) {
          submitBtn.disabled = true;
          pwdErrorMessage.innerHTML = 'La confirmation ne correspond pas au mot de passe';
        } else {
          submitBtn.disabled = false;
          pwdErrorMessage.innerHTML = '';
        }
      });
    }
  }

  const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon"
  ];

  if (photos) photos.addEventListener('change', e => {
    var curFiles = photos.files;
    var valid = true;
    var totalSize = 0;
    fileErrorMessage.innerHTML = '';
    if (curFiles.length > 0) {
      for (var i = 0; i < curFiles.length; i++) {
        if (validFileType(curFiles[i], fileTypes)) {
          totalSize += curFiles[i].size * 1;
        } else {
          valid = false;
        }
      }
      if (curFiles.length > 4) {
        fileErrorMessage.innerHTML += '<span>Maximum 4 fichiers</span></br>';
        photos.value = '';
      }
      if (!valid) {
        fileErrorMessage.innerHTML += '<span>Fichiers image seulement</span></br>';
        photos.value = '';
      }
      if (totalSize > 10000000) {
        fileErrorMessage.innerHTML += '<span>Maximum 10 Mo</span></br>';
        photos.value = '';
      }
    }
  });

  signupForm.addEventListener('submit', e => {
    e.preventDefault();

    if (results && addressErrorMessage && results.innerHTML === '') addressErrorMessage.innerHTML = '<span>Veuillez renseigner une adresse.</span>';
    else {
      if (addressErrorMessage) addressErrorMessage.innerHTML = '';
      submitBtn.textContent = 'Envoi en cours...';
      new FormData(signupForm);
    }
  });

  signupForm.addEventListener('formdata', async (e) => {

    // Get the form data from the event object
    var data = e.formData;
    var userData = new FormData();
    var relayData = new FormData();

    if (data.has('name')) {
      userData.set('role', data.get('role'));
      if (data.has('check')) userData.set('check', data.get('check'));
      userData.set('name', data.get('name'));
      userData.set('firstName', data.get('firstName'));
      userData.set('email', data.get('email'));
      userData.set('password', data.get('password'));
      userData.set('passwordConfirm', data.get('passwordConfirm'));
      userData.set('newsletter', data.has('newsletter'));
      data.delete('password');
      data.delete('passwordConfirm');

      var userObject = {};
      userData.forEach((value, key) => userObject[key] = value);

      const user = await signup(userObject);

      if (user.id) {
        data.set('userId', user.id);

        // // Relay creation at signup not working anymore since relay model has been updated with required but not defaulted fields (siret, vat number...)
        //   if (data.get('role') === 'store' && results) {
        //     data.append('location', JSON.stringify({
        //       address: JSON.parse(results.innerHTML).place_name,
        //       coordinates: JSON.parse(results.innerHTML).center
        //     }));
        //     relayData.set('name', data.get('companyName'));
        //     relayData.set('location', data.get('location'));


        //     var relObject = {};
        //     relayData.forEach((value, key) => relObject[key] = (key === 'location') ? JSON.parse(value) : value);

        //     const rel = await createRelay(relObject);

        //     if (rel._id) {
        //       data.set('relayId', rel._id);

        //       await updateUserWithRelay({
        //         user: user.id,
        //         myRelays: [rel._id]
        //       });
        //     }
        //   }
      }
    }

    var dataObject = {};
    // var attachments = [];
    if (photos) {
      // console.log(photos.files);
      const relayId = '1234567890';
      var i = 0;
      for (var photo of photos.files) {
        i++;
        if (/\.(jpe?g|png|gif)$/i.test(photo.name)) {
          // const photoContent = await toBase64(photo);
          // data.append(`photo-${i}`, photoContent);
          data.append(`photo-${i}`, photo, `${relayId}-${i}.jpg`);
          // attachments.push({
          //   content: photoContent,
          //   filename: `${relayId}-${i}.jpg`
          // });
        }
      }
    }
    data.delete('photos');

    // console.log(data);
    data.forEach((value, key) => dataObject[key] = (key === 'location') ? JSON.parse(value).address : value);
    // dataObject['attachments'] = attachments;
    sendEmail(dataObject);

    submitBtn.textContent = 'Envoyer';
    if (data.has('userId')) {
      var callback = function () {
        window.setTimeout(() => {
          location.assign(`/validation-compte/${data.get('userId')}/${data.get('email')}`);
        }, 2000);
      };
      showAlert('success', 'Félicitations ! Votre compte a été créé avec succès.');
      if (window.dataLayer && window.dataLayer.length > 0) gtag('event', 'conversion', {
        'send_to': 'AW-11006434381/5YcSCOut2owYEM24o4Ap',
        'event_callback': callback
      });
      callback();
    } else {
      showAlert('success', 'Nous avons bien reçu votre message et vous en remercions.');
      window.setTimeout(() => {
        location.assign('/');
      }, 2000);
    }

  });

}

if (activationForm) {
  activationForm.addEventListener('submit', e => {
    e.preventDefault();
    let data = {
      id: document.getElementById('id').value,
      email: document.getElementById('email').value,
      code: document.getElementById('code').value,
    }
    activate(data);
  });
  const newCodeBtn = document.getElementById('sendNewCode');
  if (newCodeBtn)
    newCodeBtn.addEventListener('click', e => {
      e.preventDefault();
      let data = {
        id: document.getElementById('id').value,
        email: document.getElementById('email').value
      }
      sendNewCode(data);
    });
}

if (cgForm) {
  const acceptBtn = document.querySelector('.btn--accept');
  const cgContent = document.getElementById('cgContent');
  cgContent.addEventListener('scroll', e => {
    if (cgContent.scrollTop >= cgContent.scrollHeight - window.innerHeight) acceptBtn.disabled = false;
  });
  cgForm.addEventListener('submit', e => {
    e.preventDefault();
    let data = {
      id: document.getElementById('id').value,
      email: document.getElementById('email').value,
      cg: JSON.parse(document.getElementById('cg').value),
    }
    acceptCg(data);
  });
}

if (loginForm)
  loginForm.addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const check = document.getElementById('check').value;
    login(email, password, check);
  });

if (forgotPasswordForm)
  forgotPasswordForm.addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    forgotPassword(email);
  });

if (resetPasswordForm)
  resetPasswordForm.addEventListener('submit', e => {
    e.preventDefault();
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    if (urlParams.get('t')) {
      const token = urlParams.get('t');
      resetPassword(password, passwordConfirm, token);
    } else showAlert('error', 'Cette action n\'est pas valide.');
  });

if (logOutBtn) logOutBtn.addEventListener('click', logout);

if (photo)
  photo.addEventListener('change', e => {
    photo.nextElementSibling.innerHTML = photo.value.replace(/.*[\/\\]/, '');
  });

if (userDataForm)
  userDataForm.addEventListener('submit', async e => {
    e.preventDefault();
    document.querySelector('.btn--save-settings').textContent = 'En cours...';
    const form = new FormData();
    form.append('name', document.getElementById('name').value);
    form.append('firstName', document.getElementById('firstName').value);
    form.append('email', document.getElementById('email').value);
    form.append('photo', photo.files[0]);
    // const form = {
    //   'name': document.getElementById('name').value,
    //   'firstName': document.getElementById('firstName').value,
    //   'email': document.getElementById('email').value
    // };
    await updateSettings(form, 'data');
    // document.querySelector('.btn--save-settings').textContent = 'Sauvegarder';
    setTimeout(function () {
      window.location.reload();
    }, 2500);
  });

if (userPasswordForm)
  userPasswordForm.addEventListener('submit', async e => {
    e.preventDefault();
    document.querySelector('.btn--save-password').textContent = 'En cours...';

    const passwordCurrent = document.getElementById('passwordCurrent').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    await updateSettings(
      { passwordCurrent, password, passwordConfirm },
      'password'
    );

    document.querySelector('.btn--save-password').textContent = 'Sauvegarder';
    document.getElementById('passwordCurrent').value = '';
    document.getElementById('password').value = '';
    document.getElementById('passwordConfirm').value = '';
  });

if (addEmployeeForm)
  addEmployeeForm.addEventListener('submit', async e => {
    e.preventDefault();
    var companies = [];
    if (document.getElementById('companies')) companies = JSON.parse(document.getElementById('companies').value)
    else {
      var checkedCompanies = Array.from(addEmployeeForm.querySelectorAll('input[name="companies"]:checked')).map((checkbox) => checkbox.value);
      companies = checkedCompanies;
    }
    if (companies.length === 0) {
      showAlert('error', 'Vous devez sélectionner au moins un élément.');
    } else {
      const data = {
        email: document.getElementById('email').value,
        name: document.getElementById('name').value,
        firstName: document.getElementById('firstName').value,
        companies,
      };
      await addEmployee(data);
    }
  });

if (deleteEmployeeBtn)
  // console.log(deleteEmployeeBtn);
  deleteEmployeeBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      showModal(
        'success',
        'Confirmer la suppression définitive de cet employé ?',
        [
          { function: async () => await deleteEmployee({ id: el.getAttribute('data-num') }), text: 'Confirmer' },
          { function: hideModal, text: 'Annuler' }
        ]
      );
    });
  });


///////////////////////////////////////////////////////
////////////////// RELAY MANAGEMENT ///////////////////
///////////////////////////////////////////////////////
if (relForm) {

  const id = document.getElementById('id').value;
  const results = document.getElementById('latlng');
  displayLocSearch('#geocoder', results);

  // Calendar setup and display
  const dp = document.getElementById('dp');

  displayDp(dp, {
    onSelect: instance => {
      var exclDaysStr = document.getElementById('exclDays');
      var exclDays = JSON.parse(exclDaysStr.value).map(el => convertTZ(el).toString());
      if (!instance.dateSelected || exclDays.includes(instance.dateSelected.toString())) showAlert('error', 'Vous avez déjà sélectionné cette date.')
      else addExclDay(instance.dateSelected);
    },
    startDay: 1,
    startDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    alwaysShow: true,
    customDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    showAllDates: true,
    disableYearOverlay: true,
    disabler: date => (date < new Date(Date.now() + 30 * 24 * 60 * 60 * 1000))
  });

  const hoursSelect = document.querySelectorAll('.hours');
  hoursSelect.forEach(el => {
    el.addEventListener('change', e => {
      e.preventDefault();
      changeHours(el);
    });
  });

  relForm.addEventListener('submit', async e => {
    e.preventDefault();
    var location = (id && document.getElementById('address')) ? {
      type: 'Point',
      coordinates: [
        document.getElementById('lng').value * 1,
        document.getElementById('lat').value * 1,
      ],
      address: document.getElementById('address').value
    } : null;

    if (results.innerText !== '') {
      const loc = JSON.parse(results.innerText);
      location = {
        type: "Point",
        coordinates: loc.center,
        address: loc.place_name
      };
    };
    var validHours = true;
    var hoursStr = document.getElementById('hours');
    var hours = JSON.parse(hoursStr.value);
    Object.values(hours).forEach(el => {
      el.forEach(e => {
        if (e.closed < e.open) {
          showAlert('error', 'L\'heure de fin du créneau ne peut pas être antérieure à son heure de début.');
          validHours = false;
          return;
        }
      });
      if (el.length > 1 && el[1].open < el[0].closed) {
        showAlert('error', 'Deux créneaux ne peuvent pas se chevaucher.');
        validHours = false;
        return;
      }
    });
    if (validHours) {
      const data = {
        name: document.getElementById('name').value,
        siret: document.getElementById('siret').value,
        VATNumber: document.getElementById('VATNumber').value,
        legalStatus: document.getElementById('legalStatus').value,
        type: document.getElementById('type').value,
        capital: document.getElementById('capital').value,
        location,
        hours,
        specialClosing: JSON.parse(document.getElementById('exclDays').value),
        idRecto: document.getElementById('idRecto').files[0],
      };

      document.querySelector('.btn--save').textContent = 'En cours...';
      if (id) {
        const idRecto = document.getElementById('idRecto');
        const idVerso = document.getElementById('idVerso');
        const kbis = document.getElementById('kbis');
        const rib = document.getElementById('rib');
        const erp = document.getElementById('erp');

        const form = new FormData();
        // for (let input of [idRecto, idVerso, kbis, rib, erp]) {
        for (let input of [idRecto, idVerso, kbis]) {
          if (input.files && input.files.length > 0) form.append(input.name, input.files[0]);
        }
        form.append('name', document.getElementById('name').value);
        form.append('siret', document.getElementById('siret').value);
        form.append('VATNumber', document.getElementById('VATNumber').value);
        form.append('legalStatus', document.getElementById('legalStatus').value);
        form.append('type', document.getElementById('type').value);
        form.append('capital', document.getElementById('capital').value);
        form.append('location', JSON.stringify(location));
        form.append('hours', document.getElementById('hours').value);
        form.append('specialClosing', document.getElementById('exclDays').value);

        await updateRelay(form, id);

        setTimeout(function () {
          window.location.reload();
        }, 2500);
      } else {
        await createMyRelay(data);
        setTimeout(function () {
          window.location.reload();
        }, 2500);
      }
    }
  });
}

if (removeRelBtn)
  // console.log(removeRelBtn);
  removeRelBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      showModal(
        'success',
        'Confirmer la suppression définitive du Relais ?',
        [
          { function: async () => await removeMyRelay(el.getAttribute('data-rel')), text: 'Confirmer' },
          { function: hideModal, text: 'Annuler' }
        ]
      );
    });
  });


if (relLinkBtn)
  relLinkBtn.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      relLinkBtn.forEach(link => { link.classList.remove('selected') });
      el.classList.add('selected');
      for (var step = 1; step < 4; step++) {
        document.getElementById('relScreen' + step).style.display = 'none';
      }
      document.getElementById('relScreen' + el.dataset.step).style.display = 'block';
    });
  });

if (favoritesIcon)
  favoritesIcon.forEach(el => {
    el.addEventListener('click', async e => {
      const toggle = await toggleFavorites(el.getAttribute("id"));
      if (toggle === true) {
        el.classList.toggle('favorite__heart--active');
        el.classList.toggle('favorite__heart--inactive');
      }
    });
  });

// if (toggleButtons)
//   toggleButtons.forEach(el => {
//     el.addEventListener('click', async e => {
//       const toggle = await toggleTakeDrop(el.getAttribute("id"));
//       if (toggle === true) {
//         // el.classList.toggle('favorite__heart--active');
//         // el.classList.toggle('favorite__heart--inactive');
//       }
//     });
//   });

if (searchPaths || searchDels) {
  const results1 = document.getElementById('latlng1');
  const results2 = document.getElementById('latlng2');
  displayLocSearch('#geocoder1', results1);
  displayLocSearch('#geocoder2', results2);

  const searchForm = (searchPaths) ? searchPaths : searchDels;
  searchForm.addEventListener('submit', async e => {
    e.preventDefault();
    // const radius = document.getElementById('radius').value;

    if (results1.innerText) {
      const loc1 = JSON.parse(results1.innerText);
      let arr = loc1.center;
      [arr[0], arr[1]] = [arr[1], arr[0]];
      let data = {};
      let type = '';

      if (!results2.innerText) {
        showAlert('error', 'Veuillez renseigner une destination.');
        // data = {
        //   latlng: arr,
        //   radius
        // };
        // type = 'locAndRad';
      } else {
        const loc2 = JSON.parse(results2.innerText);
        let arr2 = loc2.center;
        [arr2[0], arr2[1]] = [arr2[1], arr2[0]];

        data = {
          latlng1: arr,
          latlng2: arr2
        };
        type = 'twoLocs';
      }

      const docs = (searchPaths) ? await searchDocs(data, type, 'paths') : await searchDocs(data, type, 'deliveries');
      if (docs) {
        generateFormAndSubmit((searchPaths) ? '/trajets' : '/livraisons', JSON.stringify(Object.values(docs)), xsrfToken, JSON.stringify(data));
      }
    } else {
      showAlert('error', 'Veuillez renseigner une localisation de départ.');
      // document.querySelectorAll('mapboxgl-ctrl-geocoder--input').forEach(el => el.value = '');
    }
  });
}

if (payPacketForm) {

  initialize();

  async function initialize() {
    let stripe = Stripe(await getStripePublicKey());
    let elements = stripe.elements();
    const packetId = document.getElementById('packetId').value;
    const targetTime = new Date(document.getElementById('createdAt').value).getTime() + 20 * 60 * 1000;

    let cntdwn = countdown(targetTime);
    document.getElementById('countdown').innerHTML = cntdwn.message;
    let payment = await checkPayment();

    if (payment.status === 0) {
      if (cntdwn.over) {

        document.querySelector('.form--pay-packet button[type="submit"]').style.display = 'none';

      } else {
        const paymentIntent = await createPaymentIntent(packetId);
        if (paymentIntent.status === 'success') {
          // Si l'intention de paiement est créée avec succès
          // Update the countdown every second
          setInterval(() => {
            cntdwn = countdown(targetTime);
            document.getElementById('countdown').innerHTML = cntdwn.message;
            if (cntdwn.over) {
              location.reload();
            }
          }, 1000); // Update every second (1000 milliseconds)
          const clientSecret = paymentIntent.clientSecret;

          const appearance = {
            theme: 'flat',
            // theme: 'stripe',
          };
          elements = stripe.elements({ appearance, clientSecret });

          const paymentElementOptions = {
            layout: "tabs",
          };

          const paymentElement = elements.create("payment", paymentElementOptions);
          paymentElement.mount("#payment-element");
        } else {
          // Si l'intention de paiement n'est pas créée avec succès
          document.querySelector('.form--pay-packet button[type="submit"]').style.display = 'none';
          document.getElementById('countdown').style.display = 'none';
          showMessage(paymentIntent.message);
        }
      }
    } else if (payment.status === 2) {
      // Après confirmation du succès du paiement
      document.querySelector('.form--pay-packet button[type="submit"]').style.display = 'none';
      document.getElementById('countdown').style.display = 'none';
      showMessage(payment.message);
      // Affichage du bouton pour générer l'étiquette
      let generateLabelBtn = document.getElementById('generate-label');
      generateLabelBtn.classList.remove('hidden');
      generateLabelBtn.addEventListener('click', async e => {
        e.preventDefault();
        const piId = new URLSearchParams(window.location.search).get(
          // "payment_intent_client_secret"
          "payment_intent"
        );
        const labelStatus = await getPaidPacketLabel(packetId, piId);
        if (labelStatus === 'success') {
          window.open(`/mon-colis/${packetId}/${piId}/etiquette`);
        }
      });
    } else if (payment.status === -1) {
      // Après confirmation de l'échec du paiement
      document.querySelector('.form--pay-packet button[type="submit"]').style.display = 'none';
      document.getElementById('countdown').style.display = 'none';
      showMessage(payment.message);
      document.getElementById('new-attempt').classList.remove('hidden');
    }


    payPacketForm.addEventListener('submit', async e => {
      e.preventDefault();
      setLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        showMessage(error.message);
      } else {
        showMessage("Une erreur inattendue s'est produite.");
      }

      setLoading(false);
    });

    // Fetches the payment intent status after payment submission
    async function checkPayment() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return {
          status: 0
        };
      }

      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
      let message = '';
      let status = -1;

      switch (paymentIntent.status) {
        case "succeeded":
          message = "Paiement effectué avec succès !";
          status = 2;
          break;
        case "processing":
          message = "Paiement en cours...";
          status = 1;
          break;
        default:
          message = "Votre paiement a échoué. Veuillez recommencer SVP.";
          break;
      }

      return {
        status,
        message
      }
    }

    // ------- UI helpers -------

    function showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");

      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;
    }

    // Show a spinner on payment submission
    function setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("button[type='submit']").disabled = true;
        document.querySelector("#btn-spin").classList.remove("hidden");
        // document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("button[type='submit']").disabled = false;
        document.querySelector("#btn-spin").classList.add("hidden");
        // document.querySelector("#button-text").classList.remove("hidden");
      }
    }
  }

}

if (createStripeAccountBtn) createStripeAccountBtn.addEventListener('click', async e => {
  e.preventDefault();
  let relay = createStripeAccountBtn.dataset.relay;
  if (relay) await createStripeAccount(relay);
  else await createStripeAccount();
});

if (viewNextTransactionsBtn) viewNextTransactionsBtn.addEventListener('click', async e => {
  e.preventDefault();
  let lasttransaction = viewNextTransactionsBtn.dataset.lasttransaction;
  if (lasttransaction) {
    const nextTransactions = await getStripeTransactions(lasttransaction);
    if (nextTransactions) {
      for (let transaction of nextTransactions.data) {
        let div = document.createElement('div');
        div.innerHTML = `<div class='flex flex-row center' style='font-size:1.5rem;'>` +
          `<div class='quote w25' style='text-align:center;'>${transaction.type}</div>` +
          `<div class='quote w25' style='text-align:center;'>${transaction.description || '-'}</div>` +
          `<div class='w25' style='text-align:center;'>${(transaction.amount / 100).toFixed(2).replace('.', ',')} €</div>` +
          `<div class='w25' style='text-align:center;'>` +
          `${new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'Europe/Paris' }).format(new Date(transaction.created * 1000))}` +
          `</div></div><div class='spacer-blank'></div>`;
        document.getElementById('transactionsContainer').appendChild(div);
      }
      if (nextTransactions.has_more) viewNextTransactionsBtn.dataset.lasttransaction = nextTransactions.lastTransaction;
      else viewNextTransactionsBtn.remove();
    }
  }
});

if (requestPayoutBtn) requestPayoutBtn.addEventListener('click', async e => {
  e.preventDefault();
  await createStripePayout();
});

// // Pour tester Stripe (depuis l'écran expédier)
// import axios from 'axios';
// ///////////////////////////////////////////////////////
// // Penser à supprimer la route dans paymentRoutes aussi
// ///////////////////////////////////////////////////////

if (sendPacketForm) {

  // // Pour tester Stripe (depuis l'écran expédier)
  // document.getElementById('testStripe').addEventListener('click', async e => {
  //   e.preventDefault();
  //   // let data = {
  //   //   packet_id: '64df7d3270a65b988e06904e',
  //   //   source_transaction: 'azdzazdaadadad'
  //   // }
  //   try {
  //     const url = `/api/v1/payments/test-stripe`;

  //     const res = await axios({
  //       method: 'GET',
  //       url,
  //       // data
  //     });

  //     if (res.data.status === 'success') {
  //       console.log(res.data);
  //     }

  //   } catch (err) {
  //     showAlert('error', err);
  //   }
  // });

  const results1 = document.getElementById('latlng1');
  const results2 = document.getElementById('latlng2');
  displayLocSearchAndReturnRelaysList('#geocoder1', results1);
  displayLocSearchAndReturnRelaysList('#geocoder2', results2);

  const h1 = document.querySelector('main h1');
  const steps = document.querySelectorAll('.step');
  var inputs = [];
  for (var [index, value] of steps.entries()) {
    inputs[index] = Array.from(value.querySelectorAll('input.packet-input'));
  }
  const changeStepBtn = document.querySelectorAll('.step-button');
  const mapContainer = document.querySelector('section.section-map');
  var step = 0;
  var packetData = {};

  startSendForm.addEventListener('click', e => {
    step = 1;
    document.getElementById('send-packet').style.display = 'flex';
    document.getElementById('sendPacketIntro').style.display = 'none';
  });

  function getNextDates(excDs = [], h = []) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let count = 0;
    let dates = [];
    let date = new Date();
    while (count < 60) {
      let d = new Date();
      d.setDate(date.getDate() + count); // Move to the next day
      d.setHours(0, 0, 0, 0); // Set the time to midnight
      let i = d.getDay();
      if (!excDs.includes(convertTZ(d).toString())) {
        if (h[i].length === 2) {
          dates.push(convertTZ(d).toString());
          d.setHours(13, 0, 0, 0); // Set the time to midnight
          dates.push(convertTZ(d).toString());
        } else if (h[i].length === 1 && h[i][0][0] < 780) {
          dates.push(convertTZ(d).toString());
        } else if (h[i].length === 1) {
          d.setHours(13, 0, 0, 0); // Set the time to midnight
          dates.push(convertTZ(d).toString());
        }
      }
      count++;
    }
    return dates;
  }

  function validateNumberInput(input) {
    const value = parseFloat(input.value);
    const step = (input.step) ? parseFloat(input.step) : 1;
    const min = (input.min) ? parseFloat(input.min) : 0;
    const max = (input.max) ? parseFloat(input.max) : 10000;
    // console.log(value, step, min, max);
    if (isNaN(value) || (step && isNaN(step)) || (min && isNaN(min)) || (max && isNaN(max))) {
      return false;
    }
    if (value < min || value > max || value % step !== 0) return false;
    else return true;
  }

  function validateStepInputs(step) {
    var currentStepInputs = inputs[step - 1];
    var result = true;
    var expandedDims = 0;
    for (let input of currentStepInputs) {
      // console.log(input.value);
      if (input.type === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(input.value)) {
          result = false;
        }
      } else if (input.type === 'number' && !validateNumberInput(input)) {
        result = false;
      } else if (input.name === 'length' || input.name === 'width' || input.name === 'height') {
        expandedDims += input.value * 1;
      } else if (input.value.trim() === '') {
        result = false;
      }
    }
    if (expandedDims > 170) result = false;
    return result;
  }

  async function updateSummary(step) {
    if (step === 1) {
      document.querySelector('.sender').innerText = document.getElementById('senderFirstName').value + ' ' + document.getElementById('senderName').value;
      document.querySelector('.senderEmail').innerText = document.getElementById('senderEmail').value;
      document.querySelector('.senderRel').innerText = document.querySelector('#relsContainer1 .selected h6 span').innerText;
      document.querySelector('.senderAddress').innerText = document.querySelector('#relsContainer1 .selected>div>span').innerText;
      document.querySelector('.senderDate').innerText = new Date(document.getElementById('date').value).toLocaleDateString();
      document.querySelector('.senderSlot').innerText = document.querySelector('.radiobtn input[name="slot"]:checked+label small').innerText;
      document.getElementById('step1-summary').style.display = 'block';
    } else if (step === 2) {
      document.querySelector('.customer').innerText = document.getElementById('customerFirstName').value + ' ' + document.getElementById('customerName').value;
      document.querySelector('.customerEmail').innerText = document.getElementById('customerEmail').value;
      document.querySelector('.customerRel').innerText = document.querySelector('#relsContainer2 .selected h6 span').innerText;
      document.querySelector('.customerAddress').innerText = document.querySelector('#relsContainer2 .selected>div>span').innerText;
      document.getElementById('step2-summary').style.display = 'block';
    } else if (step === 3) {
      document.querySelector('.packetSize').innerText = document.getElementById('length').value + ' cm x ' + document.getElementById('width').value + ' cm x ' + document.getElementById('height').value + ' cm';
      document.querySelector('.packetWeight').innerText = document.querySelector('.radiobtn input[name="weight"]:checked+label').innerText;
      document.getElementById('step3-summary').style.display = 'block';
      let data = document.querySelectorAll('.packet-input');
      let pathsContainer = document.getElementById('paths');
      pathsContainer.innerHTML = '';
      for (let d of data) {
        packetData[d.id] = d.value;
      }
      // packetData
      // console.log(packetData);
      const paths = await getPacketPaths(packetData);

      let n = 0;
      for (let path of paths) {

        let div = document.createElement('div');
        div.style = 'display:flex;justify-content:space-between;align-items:center;border: 1px solid; border-radius: 5px; margin: 5px 0; padding: 5px;font-size:1.3rem;'

        let input = document.createElement('input');
        input.type = 'radio';
        input.name = 'path';
        input.value = n;
        input.style = 'display:block;width:8%;';
        input.checked = (n === 0);
        div.appendChild(input);

        let subDiv1 = document.createElement('div');
        subDiv1.style = 'width:50%;display:flex;flex-direction:column;justify-content:space-between;align-items:baseline;font-weight:600;margin:0.5rem 0 0.5rem 0;';
        if (path.ratioPart !== 0) {
          const dels = path.deliveries.reduce((dels, el) => {
            if (el.type === 'part') dels.push({
              shortName: el.carrier.firstName,
              id: el.carrier._id,
              photo: el.carrier.photo
            });
            return dels;
          }, []);
          let uniq = uniqByKeepLast(dels, it => it.id);
          subDiv1.innerHTML = `Un ou plusieurs particuliers vont réaliser ${path.ratioPart}% du transport.`;
          for (let i = 0; i < uniq.length; i++) {
            let content = '<div style="display:flex;padding:0.5rem;align-items:center;"><img class="carrier-photo" ';
            content += 'src="' + uniq[i].photo;
            content += '" alt="Photo miniature de ' + uniq[i].shortName + '">';
            content += '<span>' + uniq[i].shortName + '</span>';
            content += '</div>';
            let carrierDiv = document.createElement('div');
            carrierDiv.innerHTML = content;
            subDiv1.appendChild(carrierDiv);
          }
        }
        if (path.ratioPart !== 100) {
          let content = `Le ${(path.ratioPart === 0) ? '' : 'reste du '}transport sera pris en charge par des particuliers en priorité ou par des professionnels si aucun particulier n'est disponible.`;
          let carcolisDiv = document.createElement('div');
          carcolisDiv.innerHTML = content;
          subDiv1.appendChild(carcolisDiv);
        }
        div.appendChild(subDiv1);

        let subDiv2 = document.createElement('div');
        subDiv2.style = 'width:40%;display:flex;flex-direction:column;justify-content:space-between;align-items:baseline;font-weight:600;margin:0.5rem 0 0.5rem 0;';
        let priceDiv = document.createElement('div');
        priceDiv.style = 'font-size:2.5rem;display:flex;justify-content:space-between;align-items:center;font-weight:600;margin:0.5rem 0 0.5rem 0;';
        priceDiv.innerHTML = `${Number(path.price.priceTaxIncluded).toFixed(2)} €`;
        subDiv2.appendChild(priceDiv);
        let sizeDiv = document.createElement('div');
        sizeDiv.style = 'font-size:1.8rem;display:flex;justify-content:space-between;align-items:center;font-weight:600;margin:0.5rem 0 0rem 0;';
        sizeDiv.innerHTML = `Taille ${path.price.size.name}`;
        subDiv2.appendChild(sizeDiv);
        let infoDiv = document.createElement('div');
        infoDiv.style = 'display:flex;justify-content:space-between;align-items:center;font-weight:600;margin:0rem 0 0.5rem 0;';
        infoDiv.innerHTML = `(Poids < ${path.price.size.maxWeight} Kg et Déroulé < ${path.price.size.maxExpandedDims} cm)`;
        subDiv2.appendChild(infoDiv);
        let scheduleDiv = document.createElement('div');
        scheduleDiv.style = 'display:flex;justify-content:space-between;align-items:center;font-weight:600;margin:0.5rem 0 0.5rem 0;';
        scheduleDiv.innerHTML = `Retrait estimé à partir du ${new Date(path.date).toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })}`;
        subDiv2.appendChild(scheduleDiv);

        div.appendChild(subDiv2);
        pathsContainer.appendChild(div);

        n++;

      }
      // console.log(JSON.stringify(paths));
    } else if (step === 4) {
      // document.querySelector('.customer').innerText = document.getElementById('customerFirstName').value + ' ' + document.getElementById('customerName').value;
      // document.querySelector('.customerEmail').innerText = document.getElementById('customerEmail').value;
      // document.querySelector('.customerRel').innerText = document.querySelector('#relsContainer2 .selected h6 span').innerText;
      // document.querySelector('.customerAddress').innerText = document.querySelector('#relsContainer2 .selected>div>span').innerText;
      document.getElementById('step4-summary').style.display = 'block';
      // console.log(packetData);
    }
  }

  inputs.forEach(formStep => {
    formStep.forEach(input => {
      input.addEventListener('change', e => {
        if (input.type === 'email') {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(input.value)) {
            input.classList.add('input-error');
          } else input.classList.remove('input-error');
        } else if (input.name === 'length' || input.name === 'width' || input.name === 'height') {
          if (!validateNumberInput(input)) input.classList.add('input-error');
          else input.classList.remove('input-error');
          let length = document.getElementById('length').value * 1;
          let width = document.getElementById('width').value * 1;
          let height = document.getElementById('height').value * 1;
          let total = document.getElementById('total');
          total.value = length + width + height;
          if (!validateNumberInput(total)) total.classList.add('input-error');
          else total.classList.remove('input-error');
          // console.log(total);
          // if (length > 0 && width > 0 && length > 0) {
          //   console.log(document.getElementById('total'));
          //   if (total > 170) document.getElementById('total').add('input-error');
          //   else document.getElementById('total').remove('input-error');
          // }
        }
        document.querySelector(`#step${step} button.next`).disabled = (validateStepInputs(step)) ? false : true;
      });
    });
  })

  changeStepBtn.forEach(btn => {
    btn.addEventListener('click', async e => {
      step = btn.getAttribute('data-target-step') * 1;
      if (btn.classList.contains('next')) await updateSummary(step - 1);
      if (step === 1 || step === 2) {
        mapContainer.style.display = 'block';
        if (document.getElementById('latlng' + step).innerText !== '') {
          let latlng = document.getElementById('latlng' + step).innerText;
          document.getElementById('latlng' + step).innerText = latlng;
        }
      } else {
        mapContainer.style.display = 'none';
      }
      steps.forEach((stepElement, index) => {
        if (index + 1 === step) {
          stepElement.style.display = 'block';
        } else {
          stepElement.style.display = 'none';
        }
      });
      h1.scrollIntoView({ behavior: "smooth" });
    });
  });

  // Calendar setup and display
  const dpContainer = document.getElementById('dp');
  var dates = [];
  var date = document.getElementById('date');
  var slot = document.getElementById('slot');
  const slots = document.querySelectorAll('input[name="slot"]');
  var availDate = document.getElementById('availDate');
  var availSlot = document.getElementById('availSlot');
  var shipDate = document.getElementById('shipDate');
  var shipSlot = document.getElementById('shipSlot');
  var shipLimit1 = document.getElementById('shipLimit1');
  var shipLimit2 = document.getElementById('shipLimit2');
  var startRel = document.getElementById('startRel');
  var endRel = document.getElementById('endRel');

  function getNextSlot() {
    if (date.value !== '' && slot.value !== '') {
      let s = slot.value * 1;
      let d = new Date(date.value);
      if (s === 2) d.setHours(d.getHours() + 13);

      let dAvail = new Date(dates[dates.indexOf(d.toString()) + 1]);
      let sAvail = (dAvail.getHours() === 13) ? 2 : 1;
      dAvail.setHours(0, 0, 0, 0);
      availDate.value = dAvail;
      availSlot.value = sAvail;
      shipDate.value = new Date(date.value);
      shipSlot.value = s;
    }
  }

  slots.forEach(s => {
    s.addEventListener('change', e => {
      slot.value = document.querySelector('input[name="slot"]:checked').value;
      shipLimit1.value = document.querySelector('input[name="slot"]:checked').dataset.shiplimit1;
      shipLimit2.value = document.querySelector('input[name="slot"]:checked').dataset.shiplimit2;
      getNextSlot();
      document.querySelector(`#step1 button.next`).disabled = (validateStepInputs(1)) ? false : true;
    });
  });

  var weight = document.getElementById('weight');
  document.querySelectorAll('input[name="weight"]').forEach(w => {
    w.addEventListener('change', e => {
      weight.value = document.querySelector('input[name="weight"]:checked').value;
      document.querySelector(`#step3 button.next`).disabled = (validateStepInputs(3)) ? false : true;
    })
  });

  var rels = JSON.parse(document.getElementById('map').dataset.rels);
  const hours = JSON.parse(document.getElementById('hours').value);
  const excDays = JSON.parse(document.getElementById('excDays').value);
  const minDate = new Date(Date.now());
  const maxDate = new Date(Date.now() + 15 * 24 * 60 * 60 * 1000);

  var s1 = document.getElementById('slot1');
  var s2 = document.getElementById('slot2');
  var slot1 = document.getElementById('label1');
  var slot2 = document.getElementById('label2');
  var lab1Text = document.getElementById('lab1Text');
  var lab2Text = document.getElementById('lab2Text');

  var dpOptions = {
    startDay: 1,
    startDate: new Date(Date.now()),
    alwaysShow: true,
    customDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    showAllDates: true,
    disableYearOverlay: true,
    minDate,
    maxDate,
    disabler: date => true
  };
  var dp = displayAndReturnDp(dpContainer, dpOptions);

  dp.disabled = true;

  const observer = new MutationObserver(function (mutationsList) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
        if (mutation.target === startRel) {
          availDate.value = '';
          availSlot.value = '';
          s1.checked = false;
          s2.checked = false;
          document.querySelector(`#step1 button.next`).disabled = (validateStepInputs(1)) ? false : true;

          dp.remove();
          const h = hours[startRel.value];
          let latlng = rels.filter(e => (e._id === startRel.value))[0].location.coordinates;
          results1.innerText = latlng.slice().reverse();

          const now = Date.now();
          const midnight = new Date();
          midnight.setHours(0, 0, 0, 0);
          const minutesFromMidnight = Math.floor((now - midnight) / (1000 * 60));
          const todayHours = h[midnight.getDay()];
          let todayClosed = false;
          var limit1 = 0;
          var limit2 = 0;
          if (todayHours.length === 2) {
            limit1 = todayHours[0][1] - 120;
            limit2 = todayHours[1][1] - 120;
            if (minutesFromMidnight > limit2) todayClosed = true;
          } else if (todayHours.length === 1 && todayHours[0][0] < 780) {
            limit1 = todayHours[0][1] - 120;
            if (minutesFromMidnight > limit1) todayClosed = true;
          } else if (todayHours.length === 1 && todayHours[0][0] >= 780) {
            limit2 = todayHours[0][1] - 120;
            if (minutesFromMidnight > limit2) todayClosed = true;
          }

          dpOptions.onSelect = instance => {
            // Show 1 or 2 slots depending on selected date
            let d = instance.dateSelected;
            date.value = instance.dateSelected;
            slot.value = '';
            if (!d) {
              if (!slot1.classList.contains('inactive')) slot1.classList.add('inactive');
              if (!slot2.classList.contains('inactive')) slot2.classList.add('inactive');
            } else {
              let i = new Date(instance.dateSelected).getDay();
              if (h[i].length === 2) {
                if (slot1.classList.contains('inactive')) slot1.classList.remove('inactive'); lab1Text.innerText = `Entre ${minutesToHm(h[i][0][0])} et ${minutesToHm(h[i][0][1])}`;
                if (slot2.classList.contains('inactive')) slot2.classList.remove('inactive'); lab2Text.innerText = `Entre ${minutesToHm(h[i][1][0])} et ${minutesToHm(h[i][1][1])}`;
                s1.dataset.shiplimit1 = h[i][0][0];
                s1.dataset.shiplimit2 = h[i][0][1];
                s2.dataset.shiplimit1 = h[i][1][0];
                s2.dataset.shiplimit2 = h[i][1][1];
              } else if (h[i][0][0] < 780) {
                if (slot1.classList.contains('inactive')) slot1.classList.remove('inactive'); lab1Text.innerText = `Entre ${minutesToHm(h[i][0][0])} et ${minutesToHm(h[i][0][1])}`; s1.checked = true;
                if (!slot2.classList.contains('inactive')) slot2.classList.add('inactive'); lab2Text.innerText = 'Fermé';
                s1.dataset.shiplimit1 = h[i][0][0];
                s1.dataset.shiplimit2 = h[i][0][1];
                s2.dataset.shiplimit1 = '';
                s2.dataset.shiplimit2 = '';
              } else {
                if (!slot1.classList.contains('inactive')) slot1.classList.add('inactive'); lab1Text.innerText = 'Fermé';
                if (slot2.classList.contains('inactive')) slot2.classList.remove('inactive'); lab2Text.innerText = `Entre ${minutesToHm(h[i][0][0])} et ${minutesToHm(h[i][0][1])}`; s2.checked = true;
                s1.dataset.shiplimit1 = '';
                s1.dataset.shiplimit2 = '';
                s2.dataset.shiplimit1 = h[i][1][0];
                s2.dataset.shiplimit2 = h[i][1][1];
              }
              if (d.toString() === midnight.toString() && minutesFromMidnight > limit1 && !slot1.classList.contains('inactive')) slot1.classList.add('inactive');
              if (d.toString() === midnight.toString() && minutesFromMidnight > limit2 && !slot2.classList.contains('inactive')) slot2.classList.add('inactive');
              s1.checked = false;
              s2.checked = false;
            }
          }

          var excDs = excDays[startRel.value].map(el => convertTZ(el).toString());

          dates = getNextDates(excDs, h);

          dpOptions.disabler = date => (!dates.includes(date.toString()) || (date.toString() === midnight.toString() && todayClosed));
          dp = displayAndReturnDp(dpContainer, dpOptions);
        } else if (mutation.target === endRel) {
          let latlng = rels.filter(e => (e._id === endRel.value))[0].location.coordinates;
          results2.innerText = latlng.slice().reverse();
          document.querySelector(`#step2 button.next`).disabled = (validateStepInputs(2)) ? false : true;
        } else if (mutation.target === availDate || mutation.target === availSlot) {
          document.querySelector(`#step1 button.next`).disabled = (validateStepInputs(1)) ? false : true;
        }
      }
    }
  });

  observer.observe(startRel, { attributes: true });
  observer.observe(endRel, { attributes: true });

  const observer2 = new MutationObserver(function (mutationsList) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        var lnglat = mutation.target.innerText.split(',').map(parseFloat).reverse();
        map.flyTo({
          zoom: 12,
          center: lnglat,
          duration: 1200,
          easing: (t) => t
        });
      }
    }
  });

  observer2.observe(results1, { childList: true, subtree: true });
  observer2.observe(results2, { childList: true, subtree: true });
  observer2.observe(latlng1, { childList: true, subtree: true });
  observer2.observe(latlng2, { childList: true, subtree: true });

  sendPacketForm.addEventListener('submit', async e => {
    e.preventDefault();
    document.querySelector('button[type="submit"]').textContent = 'En cours...';

    let chosenPath = document.querySelector('input[type="radio"][name="path"]:checked').value;
    packetData.chosenPath = chosenPath;
    // console.log(packetData, chosenPath);

    let packetShipDate = new Date(packetData.shipDate);
    let hourLimit = packetData.shipLimit2 * 1;
    const now = Date.now();
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0);
    const minutesFromMidnight = Math.floor((now - midnight) / (1000 * 60));

    if (packetShipDate.toString() === midnight.toString() && minutesFromMidnight > hourLimit - 90) {
      showAlert('error', 'Vous ne pouvez plus sélectionner ce créneau d\'expédition. Veuillez recommencer...');
      await delay(3000);
      window.location.reload();
    } else {
      await createPacket(packetData);
    }


    // var shopData = document.getElementById('startRel').value;
    // var clientShop = shopData.slice(1, -1).split(',')[0];
    // var startRel = shopData.slice(1, -1).split(',')[1];

    // // // Modification suite à ajout du système de formats de colis prédéfinis au niveau de la boutique (plus besoin de spécifier les dimensions)
    // // var defaultPacketSize = clientShop.sizes[clientShop.defaultSize];
    // // var weight = defaultPacketSize.maxWeight || document.getElementById('weight').value;
    // // var length = defaultPacketSize.l || document.getElementById('length').value;
    // // var width = defaultPacketSize.w || document.getElementById('width').value;
    // // var height = defaultPacketSize.h || document.getElementById('height').value;
    // var weight = (document.getElementById('weight')) ? document.getElementById('weight').value : 0;
    // var length = (document.getElementById('length')) ? document.getElementById('length').value : 0;
    // var width = (document.getElementById('width')) ? document.getElementById('width').value : 0;
    // var height = (document.getElementById('height')) ? document.getElementById('height').value : 0;

    // var data = {
    //   customerName: document.getElementById('customerName').value,
    //   customerFirstName: document.getElementById('customerFirstName').value,
    //   customerEmail: document.getElementById('customerEmail').value,
    //   weight,
    //   length,
    //   width,
    //   height,
    //   clientShop,
    //   startRel,
    //   availDate: document.getElementById('date').value,
    //   availSlot: document.getElementById('slot').value,
    //   endRel: (document.querySelector('input[name="endRel"]:checked')) ? document.querySelector('input[name="endRel"]:checked').value : '',
    //   chosenPath: (document.querySelector('input[name="chosenPath"]:checked')) ? document.querySelector('input[name="chosenPath"]:checked').value * 1 : ''
    // };

    // // if (data.length < data.width || data.width < data.height) {
    // //   showAlert('error', 'La longueur ne peut pas être inférieure à la largeur et la largeur ne peut pas être inférieure à la hauteur.');
    // // } else if (data.date === '' || data.endRel === '') {
    // if (data.date === '' || data.endRel === '') {
    //   showAlert('error', 'Veuillez renseigner une date et sélectionner un relais d\'arrivée avant de continuer.');
    // } else {
    //   if (data.chosenPath === '') {
    //     const paths = await getPacketPaths(data);
    //     if (paths) {
    //       document.querySelector('.btn--save').textContent = 'Générer le colis';
    //       var container = document.getElementById('paths');
    //       var n = 0;
    //       container.innerHTML = '';

    //       paths.forEach(e => {
    //         var radiobox = document.createElement('input');
    //         radiobox.type = 'radio';
    //         radiobox.id = 'path' + n;
    //         radiobox.value = n;
    //         radiobox.name = 'chosenPath';
    //         radiobox.style = 'display: block; float: left;'

    //         var div = document.createElement('div');
    //         // div.classList.add('path');
    //         div.style = 'border: 1px solid; border-radius: 5px; margin: 5px 0; padding: 5px;'
    //         var label = document.createElement('label')
    //         label.htmlFor = 'path' + n;

    //         var span = document.createElement('span');
    //         var span2 = document.createElement('span');
    //         var span3 = document.createElement('span');
    //         var span4 = document.createElement('span');
    //         label.appendChild(span);
    //         label.appendChild(document.createElement('br'));
    //         label.appendChild(span2);
    //         label.appendChild(document.createElement('br'));
    //         label.appendChild(span3);
    //         label.appendChild(document.createElement('br'));
    //         label.appendChild(span4);
    //         const dels = e.deliveries.map(el => el.carrier.firstName);
    //         var text = 'L\'ensemble du trajet est assuré par Carcolis.';
    //         if (dels.length > 0) {
    //           text = `${dels[0]}`;
    //           if (dels.length > 1) {
    //             for (var i = 1; i < dels.length; i++) {
    //               text += ' et ' + dels[i];
    //             }
    //           }
    //         }
    //         var text2 = `assure${(dels.length > 1) ? 'nt' : ''} ${e.ratioPart}% du trajet total du colis.`;
    //         var text3 = `Coût du transport: ${e.price.priceTaxIncluded.toFixed(2)} €`;
    //         var text4 = `Livraison prévue le ${new Date(e.date).toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })}`;
    //         var description = document.createTextNode(text);
    //         var description2 = document.createTextNode(text2);
    //         var description3 = document.createTextNode(text3);
    //         var description4 = document.createTextNode(text4);
    //         span.appendChild(description);
    //         if (e.ratioPart !== 0) span2.appendChild(description2);
    //         span3.appendChild(description3);
    //         span4.appendChild(description4);

    //         var newline = document.createElement('br');

    //         container.appendChild(div);
    //         div.appendChild(radiobox);
    //         div.appendChild(label);
    //         div.appendChild(newline);

    //         n++;
    //       });
    //     } else {
    //       showAlert('error', 'Un problème a eu lieu. Il est probable que les dimensions du colis sont hors limite.');
    //     }
    //   } else {
    //     await createPacket(data);
    //   }
    // }
  });

  // const searchForm = (searchPaths) ? searchPaths : searchDels;
  // searchForm.addEventListener('submit', async e => {
  //   e.preventDefault();
  //   const radius = document.getElementById('radius').value;

  //   if (results1.innerText) {
  //     const loc1 = JSON.parse(results1.innerText);
  //     let arr = loc1.center;
  //     [arr[0], arr[1]] = [arr[1], arr[0]];
  //     let data = {};
  //     let type = '';

  //     if (!results2.innerText) {
  //       data = {
  //         latlng: arr,
  //         radius
  //       };
  //       type = 'locAndRad';
  //     } else {
  //       const loc2 = JSON.parse(results2.innerText);
  //       let arr2 = loc2.center;
  //       [arr2[0], arr2[1]] = [arr2[1], arr2[0]];

  //       data = {
  //         latlng1: arr,
  //         latlng2: arr2
  //       };
  //       type = 'twoLocs';
  //     }

  //     const docs = (searchPaths) ? await searchDocs(data, type, 'paths') : await searchDocs(data, type, 'deliveries');
  //     if (docs) {
  //       generateFormAndSubmit((searchPaths) ? '/trajets' : '/livraisons', JSON.stringify(Object.values(docs)), xsrfToken);
  //     }
  //   } else {
  //     showAlert('error', 'Veuillez renseigner une localisation.');
  //     // document.querySelectorAll('mapboxgl-ctrl-geocoder--input').forEach(el => el.value = '');
  //   }
  // });
}

if (postLinks)
  postLinks.forEach(el => {
    el.addEventListener('click', async e => {
      e.preventDefault();
      // console.log(user.id);
      var url = el.getAttribute('href');
      var data = el.getAttribute('data-paths');
      if (data) {
        generateFormAndSubmit(url, data, xsrfToken);
      }
    });
  });

if (eyes)
  eyes.forEach(el => {
    el.addEventListener('click', async e => {
      var parent = el.parentNode;
      var pwdField = parent.querySelector('input');
      var eyeOn = parent.querySelector('.on');
      var eyeOff = parent.querySelector('.off');
      const type = pwdField.getAttribute("type") === "password" ? "text" : "password";
      pwdField.setAttribute("type", type);
      eyeOn.style.display = (type === 'text') ? 'none' : 'block';
      eyeOff.style.display = (type === 'text') ? 'block' : 'none';
    });
  });

if (searchByFavs) {
  const relsIds = document.getElementById('favs').value;

  searchByFavs.addEventListener('submit', async e => {
    e.preventDefault();
    // console.log(user.id);
    const dels = await searchDocs(JSON.parse(relsIds), 'favRels', 'deliveries');
    if (dels) {
      generateFormAndSubmit('/livraisons', JSON.stringify(Object.values(dels)), xsrfToken);
    }
  });
}


///////////////////////////////////////////////////////
/////////////////// SCAN MANAGEMENT ///////////////////
///////////////////////////////////////////////////////
if (canvasElement) {
  if (getComputedStyle(canvasElement).display !== 'none') displayCamStream(canvasElement);
  // if (camData) console.log(camData);
}
// if (bookBtn)
//   bookBtn.addEventListener('click', e => {
//     e.target.textContent = 'Processing...';
//     const { tourId } = e.target.dataset;
//     bookTour(tourId);
//   });

if (endDelScan)
  endDelScan.addEventListener('click', e => {
    showModal(
      'success',
      'Confirmer que tous les colis ont été scannés avec succès ?',
      [
        { function: () => endDeliveryScan(window.location.pathname.split('/')[2]), text: 'Confirmer' },
        { function: hideModal, text: 'Annuler' }
      ]
    );
  });

if (declareMissing)
  declareMissing.addEventListener('click', e => {
    showModal(
      'success',
      'Le colis est-il manquant ? S\'il est disponible, veuillez le scanner.',
      [
        { function: () => declareMissingPacket(window.location.pathname.split('/')[2], declareMissing.getAttribute('data-id')), text: 'Oui' },
        { function: hideModal, text: 'Non' }
      ]
    );
  });

const alertMessage = document.querySelector('body').dataset.alert;
if (alertMessage) showAlert('success', alertMessage, 20);


///////////////////////////////////////////////////////
////////////////// ADMIN MANAGEMENT ///////////////////
///////////////////////////////////////////////////////
if (selectCRs)
  selectCRs.addEventListener('click', e => {
    var selected = Array.from(document.querySelectorAll('.CRs'));
    selected = selected.filter(el => (el.checked)).map(e => e.value);
    const i = document.getElementById('i').value;
    selectCRs.href = '/admin/a-livrer?i=' + i + '&selected=' + JSON.stringify(selected);
  });

if (deliverPathsForm)
  deliverPathsForm.addEventListener('submit', e => {
    e.preventDefault();
    var carrier = document.getElementById('userId').value;
    var dateStr = document.getElementById('date').value;
    var pathIn = JSON.parse(document.getElementById('pathIn').value);
    var pathOut = JSON.parse(document.getElementById('pathOut').value);
    var packetsIn = JSON.parse(document.getElementById('packetsIn').value);
    var packetsOut = JSON.parse(document.getElementById('packetsOut').value);
    var data = [];
    for (var pIn of pathIn) {
      var i = pathIn.indexOf(pIn);
      var pOut = pathOut[i];
      var packsIn = packetsIn[i];
      var packsOut = packetsOut[i];
      if (pOut.length > 0) {
        data.push({
          path: pOut,
          carrier,
          packsOut,
          date: new Date(dateStr * 1),
          way: 'out'
        });
      }
      if (pIn.length > 0) {
        data.push({
          path: pIn,
          carrier,
          packsIn,
          date: new Date(dateStr * 1),
          way: 'in'
        });
      }
    }
    createProDelivery(data);
  });

if (generateOrdersForm)
  generateOrdersForm.addEventListener('submit', e => {
    e.preventDefault();
    var currentDate = new Date();
    var month = document.getElementById('month').value;
    var year = document.getElementById('year').value;
    var createInvoice = document.getElementById('createInvoice').checked;
    var data = { month, year, createInvoice };
    if ((month > currentDate.getMonth() && year === currentDate.getFullYear())) showAlert('error', 'Impossible de générer les commandes dans le futur.');
    else generateOrders(data);
  });

if (generatePurchasesForm)
  generatePurchasesForm.addEventListener('submit', e => {
    e.preventDefault();
    var currentDate = new Date();
    var month = document.getElementById('month').value;
    var year = document.getElementById('year').value;
    var createInvoice = document.getElementById('createInvoice').checked;
    var data = { month, year, createInvoice };
    if ((month > currentDate.getMonth() && year === currentDate.getFullYear())) showAlert('error', 'Impossible de générer les commandes dans le futur.');
    else generatePurchases(data);
  });

if (createPaymentsForm)
  createPaymentsForm.addEventListener('submit', e => {
    e.preventDefault();
    var currentDate = new Date();
    var month = document.getElementById('month').value;
    var year = document.getElementById('year').value;
    var data = { month, year };
    if ((month > currentDate.getMonth() && year === currentDate.getFullYear())) showAlert('error', 'Impossible de générer les commandes dans le futur.');
    else createPayments(data);
  });

if (computePricesBtn)
  computePricesBtn.addEventListener('click', async e => {
    e.preventDefault();
    await computePrices();
    window.location.reload();
  });

if (shopSelect)
  shopSelect.addEventListener('change', e => {
    window.location.search = (shopSelect.value !== '') ? `?shop=${shopSelect.value}` : '';
  });


///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
//////////// SIMULATION PURPOSE ONLY //////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////

if (simulatePacketsForm || simulateDeliveriesForm || getTransactionsForm) {

  var form = simulatePacketsForm || simulateDeliveriesForm || getTransactionsForm;

  // Calendar setup and display
  var dp1 = document.getElementById('dp1');
  var dp2 = document.getElementById('dp2');
  var dateStart = '';
  var dateEnd = '';

  displayDp(dp1, {
    onSelect: instance => {
      dateStart = instance.dateSelected;
    },
    startDay: 1,
    startDate: new Date(Date.now()),
    alwaysShow: true,
    customDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    showAllDates: true,
    disableYearOverlay: true,
  });

  displayDp(dp2, {
    onSelect: instance => {
      dateEnd = instance.dateSelected;
    },
    startDay: 1,
    startDate: new Date(Date.now()),
    alwaysShow: true,
    customDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    customMonths: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    showAllDates: true,
    disableYearOverlay: true,
  });

  form.addEventListener('submit', async e => {
    e.preventDefault();
    document.querySelector('.btn--save').textContent = 'En cours...';

    if (dateStart === '' || dateEnd === '') {
      showAlert('error', 'Veuillez renseigner une date de début et une date de fin.');
    } else if (new Date(dateEnd).valueOf() < new Date(dateStart).valueOf()) {
      showAlert('error', 'Veuillez sélectionner une date de fin postérieure à la date de début.');
    } else if (new Date(dateEnd).valueOf() > new Date(dateStart).valueOf() + 62 * 24 * 60 * 60 * 1000) {
      showAlert('error', 'Veuillez sélectionner une période de 2 mois maximum.');
    } else {
      if (simulatePacketsForm) {
        var packMaxPerShop = document.getElementById('packMaxPerShop').value;
        var noCreate = document.getElementById('noCreate').checked;
        var data = { dateStart, dateEnd, packMaxPerShop, noCreate };
        await simulatePackets(data);
        document.querySelector('.btn--save').textContent = 'Générer des colis';
      } else if (getTransactionsForm) {
        dateEnd = new Date(new Date(dateEnd).valueOf() + 24 * 60 * 60 * 1000);
        var data = { dateStart, dateEnd };
        await getTransactions(data);
        document.querySelector('.btn--save').textContent = 'Afficher les résultats';
      } else if (simulateDeliveriesForm) {
        var ratioProNonPro = document.getElementById('ratioProNonPro').value;
        var trunkVol = document.getElementById('trunkVol').value;
        var data = { dateStart, dateEnd, ratioProNonPro, trunkVol };
        await simulateDeliveries(data);
        document.querySelector('.btn--save').textContent = 'Générer des livraisons';
      }
    }
  });
}

if (delsChart) {

  const pricesChart = document.getElementById('pricesChart');

  const delsData = JSON.parse(document.getElementById('delsData').value);
  const pricesData = JSON.parse(document.getElementById('pricesData').value);
  const packsData = JSON.parse(document.getElementById('packsData').value);

  const ChartDels = new Chart(
    delsChart,
    delsData
  );

  const ChartPrices = new Chart(
    pricesChart,
    pricesData
  );

  const ChartPacks = new Chart(
    packsChart,
    packsData
  );

}