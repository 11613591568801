import axios from 'axios';
import { showAlert, showModal } from './alerts.js';
import { convertTZ } from './utils.js';

let xsrfToken = localStorage.getItem('xsrfToken');
if (xsrfToken) {
  xsrfToken = JSON.parse(xsrfToken);
  axios.defaults.headers.common['x-xsrf-token'] = xsrfToken;
}

////////// SIMULATION PURPOSE ONLY /////////////
export const getRelaysWithin = async (distance, latlng) => {
  try {
    const url = `/api/v1/relays/relays-within/${distance}/center/${latlng}`;

    const res = await axios({
      method: 'GET',
      url
    });
    // if (res.data.status === 'success') {
    //   showAlert('success', 'Les données ont été mises à jour avec succès.');
    // }
    return res.data.data.rels;
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const getRelaysFromIds = async (ids) => {
  try {
    const url = `/api/v1/relays`;

    const res = await axios({
      method: 'GET',
      url
    });

    // if (res.data.status === 'success') {
    //   showAlert('success', 'Les données ont été mises à jour avec succès.');
    // }
    const allRels = res.data.data.data;
    const filteredRels = allRels.filter(r => {
      return (ids.includes(r._id) && r.type === 'pr');
    });
    const rels = filteredRels.map(r => {
      return { _id: r._id, name: r.name };
    });
    return rels;
    // console.log(rels);
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateRelay = async (data, id) => {
  try {
    const url = `/api/v1/relays/${id}/updateMyRelay`;

    // for (const value of data.values()) {
    //   console.log(value);
    // }
    const res = await axios({
      method: 'PATCH',
      url,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Le Relais a été mis à jour avec succès.');
    } else showAlert('error', res.data.data.data);
  } catch (err) {
    showAlert('error', res.data.data.data);
  }
};

export const createRelay = async (data) => {
  try {
    const url = `/api/v1/relays/createRelay`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      return res.data.data.data;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createMyRelay = async (data) => {
  try {
    const url = `/api/v1/relays/createMyRelay`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Relais créé avec succès.');
      window.location.href = `/mes-relais/${res.data.data.data._id}`
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const addHours = (el) => {
  var hoursStr = document.getElementById('hours');
  var hours = JSON.parse(hoursStr.value);
  hours[el.dataset.day].push({ "open": 1140, "closed": 1140 });
  hoursStr.value = JSON.stringify(hours);
  var div = document.createElement('div');
  div.setAttribute("data-day", el.dataset.day);
  div.setAttribute("data-slot", 1);
  div.classList.add('flex', 'flex-row', 'start', 'center')
  el.parentNode.parentNode.appendChild(div);

  var select1 = document.createElement('select');
  var span = document.createElement('span');
  var select2 = document.createElement('select');
  var rmvBtn = document.createElement('button');
  select1.setAttribute('data-day', el.dataset.day);
  select1.setAttribute('data-slot', 1);
  select1.setAttribute('data-type', 'open');
  select1.style = 'width:30%;'
  select1.classList.add('form__input', 'hours');
  span.innerHTML = ' - ';
  select2.setAttribute('data-day', el.dataset.day);
  select2.setAttribute('data-slot', 1);
  select2.setAttribute('data-type', 'closed');
  select2.style = 'width:30%;'
  select2.classList.add('form__input', 'hours');
  rmvBtn.classList.add('btn', 'btn--small', 'btn--blue', 'removeHours');
  rmvBtn.innerHTML = '<svg style="width:2.5rem;height:2.5rem;fill:#fff;"><use xlink:href="/img/icons.svg#icon-trash"></use></svg>';
  rmvBtn.title = 'supprimer ce créneau';
  rmvBtn.style = 'padding:1rem !important;margin: 1rem 2rem;';
  rmvBtn.setAttribute('data-day', el.dataset.day);
  rmvBtn.addEventListener('click', e => {
    e.preventDefault();
    removeHours(rmvBtn);
  });

  var i = 0;
  var opt1 = document.createElement('option');
  var opt2 = document.createElement('option');
  opt1.value = '';
  opt1.innerHTML = '-';
  opt2.value = '';
  opt2.innerHTML = '-';
  select1.appendChild(opt1);
  select2.appendChild(opt2);
  while (i < 47) {
    var t = 450 + i * 15;
    var h = Math.floor(t / 60);
    var m = t % (h * 60);
    m = (m === 0) ? '00' : m;

    var option1 = document.createElement('option');
    var option2 = document.createElement('option');
    option1.value = t;
    option1.innerHTML = h + ':' + m;
    option2.value = t;
    option2.innerHTML = h + ':' + m;
    if (i === 46) {
      option1.selected = true;
      option2.selected = true;
    }
    select1.appendChild(option1);
    select2.appendChild(option2);
    i++;
  }

  select1.addEventListener('change', e => {
    e.preventDefault();
    changeHours(select1);
  });
  select2.addEventListener('change', e => {
    e.preventDefault();
    changeHours(select2);
  });
  div.appendChild(select1);
  div.appendChild(span);
  div.appendChild(select2);
  div.appendChild(rmvBtn);

  el.remove();
};

export const removeHours = (el) => {
  var hoursStr = document.getElementById('hours');
  var hours = JSON.parse(hoursStr.value);
  hours[el.dataset.day].splice(el.dataset.slot, 1);
  hoursStr.value = JSON.stringify(hours);
  var addBtn = document.createElement('button');
  addBtn.classList.add('btn', 'btn--small', 'btn--blue', 'addHours');
  addBtn.title = 'ajouter un créneau (jusqu\'à 2 par jour)';
  addBtn.style = 'font-size:3rem !important;padding:1rem !important;';
  addBtn.innerHTML = '+';
  addBtn.setAttribute('data-day', el.dataset.day);
  addBtn.addEventListener('click', e => {
    e.preventDefault();
    addHours(addBtn);
  });
  el.parentNode.parentNode.firstChild.appendChild(addBtn);
  el.parentNode.remove();
};

export const changeHours = (el) => {
  var hoursStr = document.getElementById('hours');
  var hours = JSON.parse(hoursStr.value);
  hours[el.dataset.day][el.dataset.slot][el.dataset.type] = el.value * 1;
  hoursStr.value = JSON.stringify(hours);
};

export const addExclDay = (el) => {
  var exclDaysStr = document.getElementById('exclDays');
  var exclDays = JSON.parse(exclDaysStr.value).map(el => el.toString());
  var date = convertTZ(el);
  exclDays.push(date);
  exclDaysStr.value = JSON.stringify(exclDays);
  var dateContainer = document.getElementById('dateContainer');

  var div = document.createElement('div');
  div.classList.add('flex', 'flex-row', 'center', 'start');
  dateContainer.appendChild(div);

  var p = document.createElement('p');
  var span = document.createElement('span');
  var rmvBtn = document.createElement('button');
  var br = document.createElement('br');
  span.innerHTML = new Date(el).toLocaleDateString('fr');
  rmvBtn.classList.add('btn', 'btn--small', 'btn--blue', 'removeExclDays');
  rmvBtn.innerHTML = '<svg style="width:2rem;height:2rem;fill:#fff;"><use xlink:href="/img/icons.svg#icon-trash"></use></svg>';
  rmvBtn.setAttribute('data-day', date.toISOString());
  rmvBtn.setAttribute('title', 'supprimer cette date');
  rmvBtn.style = 'padding:0.5rem !important;margin: 0.5rem 1rem;';
  rmvBtn.addEventListener('click', e => {
    e.preventDefault();
    removeExclDay(rmvBtn);
  });

  p.appendChild(span);
  div.appendChild(p);
  div.appendChild(rmvBtn);
  div.parentNode.appendChild(br);
};

export const removeExclDay = (el) => {
  var exclDaysStr = document.getElementById('exclDays');
  var exclDays = JSON.parse(exclDaysStr.value).map(el => el.toString());
  var index = exclDays.indexOf(el.dataset.day)
  exclDays.splice(index, 1);
  exclDaysStr.value = JSON.stringify(exclDays);
  el.parentNode.remove();
};

export const removeMyRelay = async (id) => {
  try {
    const url = `/api/v1/relays/${id}/deleteMyRelay`;

    const res = await axios({
      method: 'PATCH',
      url
    });

    if (res.data.status === 'success') showAlert('success', 'Ce relais a été supprimé avec succès.');
    else showAlert('error', res.data.data);
    setTimeout(function () {
      window.location.href = `/mes-relais`
    }, 2500);
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};