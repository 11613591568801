import axios from 'axios';
import { showAlert, showModal } from './alerts.js';

let xsrfToken = localStorage.getItem('xsrfToken');
if (xsrfToken) {
  xsrfToken = JSON.parse(xsrfToken);
  axios.defaults.headers.common['x-xsrf-token'] = xsrfToken;
}

export const updateShop = async (data, id) => {
  try {
    const url = `/api/v1/shops/${id}/updateMyShop`;

    const res = await axios({
      method: 'PATCH',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Les données ont été mises à jour avec succès.');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const addSize = async (data, id) => {
  try {
    const url = `/api/v1/shops/${id}/addSize`;

    const res = await axios({
      method: 'PATCH',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Format de colis ajouté avec succès.');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const removeSize = async (data, id) => {
  try {
    const url = `/api/v1/shops/${id}/removeSize`;

    const res = await axios({
      method: 'PATCH',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Format de colis supprimé avec succès.');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const addKey = async (id) => {
  try {
    const url = `/api/v1/shops/${id}/addKey`;

    const res = await axios({
      method: 'POST',
      url
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Clé API générée avec succès.');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const removeKey = async (data, id) => {
  try {
    const url = `/api/v1/shops/${id}/removeKey`;

    const res = await axios({
      method: 'DELETE',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Vous avez bien supprimé la clé API.');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createShop = async (data) => {
  try {
    const url = `/api/v1/shops/createMyShop`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Boutique créée avec succès.');
      window.location.href = `/mes-boutiques/${res.data.data.data._id}`
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const creditBalance = async (data, id) => {
  try {
    const url = `/api/v1/shops/${id}/creditBalance`;

    await axios({
      method: 'PATCH',
      url,
      data
    });
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
