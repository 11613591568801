import axios from 'axios';
import { showAlert, showModal } from './alerts.js';

export const login = async (email, password, check) => {
  try {
    const queryParamsString = (window.location.search) ? [window.location.search.slice(1)] : [];
    if (queryParamsString.length > 0) var queryParams = queryParamsString
      .reduce((accumulator, singleQueryParam) => {
        const [key, value] = singleQueryParam.split('=');
        accumulator[key] = value;
        return accumulator;
      }, {});
    const query = (queryParams && queryParams.next) ? '?next=' + queryParams.next : '';

    const res = await axios({
      method: 'POST',
      url: `/api/v1/users/login${query}`,
      data: {
        email,
        password,
        check
      }
    });

    if (res.data.status === 'success') {
      localStorage.setItem('xsrfToken', JSON.stringify(res.data.xsrfToken));
      showAlert('success', 'Vous êtes connecté !');
      window.setTimeout(() => {
        if (res.data.path) location.assign(res.data.path);
        else location.assign('/tableau-de-bord');
      }, 1000);
    } else {
      showAlert('error', res.data.message);
      if (res.data.status === 'activation-error') {
        window.setTimeout(() => {
          location.assign(`/validation-compte/${res.data.id}/${res.data.email}`);
        }, 2000);
      } else if (res.data.status === 'agreement-error') {
        window.setTimeout(() => {
          location.assign(`/accepter/cgu/${res.data.id}/${res.data.email}`);
        }, 2000);
      }
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const signup = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/signup',
      data: data
    });

    if (res.data.status === 'success') {
      return res.data;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.assign('/connexion');
    }, 1500);
  }
};

export const updateUserWithRelay = async (data) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/addRelay',
      data: data
    });
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.assign('/connexion');
    }, 1500);
  }
};

export const activate = async (data) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/activate',
      data: data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Votre compte a été validé avec succès !');
      window.setTimeout(() => {
        location.assign(`/connexion?email=${res.data.email}`);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.assign('/connexion');
    }, 1500);
  }
};

export const sendNewCode = async (data) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/resetActivationCode',
      data: data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Vous avez reçu un nouveau code d\'activation dans votre boîte mail.');
      window.setTimeout(() => {
        location.assign(`/validation-compte/${res.data.id}/${res.data.email}`);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.assign(`/validation-compte/${res.data.id}/${res.data.email}`);
    }, 1500);
  }
};

export const forgotPassword = async (email) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/forgotPassword',
      data: {
        email
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Vous allez recevoir un email. Veuillez consulter votre boîte de messagerie !');
      window.setTimeout(() => {
        location.assign('/connexion');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Il n\'existe aucun compte avec cet email. Veuillez retenter l\'action SVP...');
  }
};

export const resetPassword = async (password, passwordConfirm, token) => {
  if (password === passwordConfirm) {
    try {
      const res = await axios({
        method: 'PATCH',
        url: `/api/v1/users/resetPassword/${token}`,
        data: {
          password,
          passwordConfirm
        }
      });

      if (res.data.status === 'success') {
        showAlert('success', 'Mot de passe modifié avec succès !');
        window.setTimeout(() => {
          location.assign('/tableau-de-bord');
        }, 1500);
      }
    } catch (err) {
      showAlert('error', 'Le jeton d\'authentification est invalide ou a expiré');
    }
  } else showAlert('error', 'Veuillez renseigner le même mot de passe dans les deux champs.');
};

export const logout = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/api/v1/users/logout'
    });
    if ((res.data.status = 'success')) location.assign('/connexion');
  } catch (err) {
    console.log(err.response);
    showAlert('error', 'Une erreur est survenue ! Veuillez recommencer SVP...');
  }
};

export const addEmployee = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/add-employee',
      data
    });
    if ((res.data.status = 'success')) {
      showAlert('success', 'Nouvel employé créé avec succès !');
      window.setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  } catch (err) {
    console.log(err.response);
    showAlert('error', err.response.data.message);
  }
};

export const deleteEmployee = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/delete-employee',
      data
    });
    if ((res.data.status = 'success')) {
      showAlert('success', 'Employé supprimé avec succès !');
      window.setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  } catch (err) {
    console.log(err.response);
    showAlert('error', 'Une erreur est survenue ! Veuillez recommencer SVP...');
  }
};
