export const displayHereMap = (locations) => {

  import(
    /* webpackChunkName: "mapsjs" */
    /* webpackExports: ["default"] */
    '@here/maps-api-for-javascript'
  ).then(
    ({ default: H }) => {

      function setStyle(map) {
        // get the vector provider from the base layer
        var provider = map.getBaseLayer().getProvider();
        // Create the style object from the YAML configuration.
        // First argument is the style path and the second is the base URL to use for
        // resolving relative URLs in the style like textures, fonts.
        // all referenced resources relative to the base path https://js.api.here.com/v3/3.1/styles/omv.
        var style = new H.map.render.webgl.Style('../normal.day.yaml');
        // set the style on the existing layer
        provider.setStyle(style);
      }

      // function addMarkerToGroup(group, coordinate, html) {
      //   var marker = new H.map.Marker(coordinate);
      //   // add custom data to the marker
      //   marker.setData(html);
      //   group.addObject(marker);
      // }

      var group = new H.map.Group();
      const map2 = document.getElementById('map2');
      const blue = new H.map.Icon('../img/pin-blue.png');
      const orange = new H.map.Icon('../img/pin-orange.png');
      const green = new H.map.Icon('../img/pin-green.png');
      const grey = new H.map.Icon('../img/pin-grey.png');

      var platform = new H.service.Platform({
        'apikey': 'IXvutYTloP6HxlW71ih2TNaW7AZzHE7aYmPgRQpPOn4'
      });

      // Obtain the default map types from the platform object
      var maptypes = platform.createDefaultLayers();

      // Instantiate (and display) a map object:
      var map = new H.Map(
        map2,
        maptypes.vector.normal.map
      );

      // Create the default UI:
      var ui = H.ui.UI.createDefault(map, maptypes, 'fr-FR');

      // var mapSettings = ui.getControl('mapsettings');
      // var zoom = ui.getControl('zoom');
      // var scalebar = ui.getControl('scalebar');

      setStyle(map);

      var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));



      ///////////TEST
      ///// OK but need to get locations input by user in render view

      // var routingParameters = {
      //   'routingMode': 'fast',
      //   'transportMode': 'car',
      //   // The start point of the route:
      //   'origin': `${startEndLocs[0].lat},${startEndLocs[0].lng}`,
      //   // The end point of the route:
      //   'destination': `${startEndLocs[1].lat},${startEndLocs[1].lng}`,
      //   // Include the route shape in the response
      //   'return': 'polyline'
      // };

      // // Define a callback function to process the routing response:
      // var onResult = function (result) {
      //   // ensure that at least one route was found
      //   if (result.routes.length) {
      //     result.routes[0].sections.forEach((section) => {
      //       // Create a linestring to use as a point source for the route line
      //       let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);

      //       // Create a polyline to display the route:
      //       let routeLine = new H.map.Polyline(linestring, {
      //         // style: { strokeColor: '#5c74ff', lineWidth: 4 }
      //         style: { strokeColor: '#444444', lineWidth: 4 }
      //       });

      //       // Create a marker for the start point:
      //       let startMarker = new H.map.Marker(section.departure.place.location);

      //       // Create a marker for the end point:
      //       let endMarker = new H.map.Marker(section.arrival.place.location);

      //       // Add the route polyline and the two markers to the map:
      //       map.addObjects([routeLine, startMarker, endMarker]);

      //       // Set the map's viewport to make the whole route visible:
      //       map.getViewModel().setLookAtData({ bounds: routeLine.getBoundingBox() });
      //     });
      //   }
      // };

      // // Get an instance of the routing service version 8:
      // var router = platform.getRoutingService(null, 8);

      // // Call calculateRoute() with the routing parameters,
      // // the callback and an error callback function (called if a
      // // communication error occurs):
      // router.calculateRoute(routingParameters, onResult,
      //   function (error) {
      //     alert(error.message);
      //   });



      //////////////



      // Add a marker for each location found
      locations.forEach(loc => {
        var position = {
          lat: loc.location.coordinates[1],
          lng: loc.location.coordinates[0]
        };
        var marker = new H.map.Marker(position, {
          icon: eval(loc.color),
          data: {
            name: loc.name,
            hours: loc.hoursStr
          }
        });
        // marker.setData(loc.name);
        group.addObjects([marker]);

        if (loc.relId) {
          var bubble = new H.ui.InfoBubble(position, {
            // read custom data
            content: `<div class='infoBubble' style='width:11rem;'>
                <p class='quote' style='color:grey;'>In  : <span style='color:green;'>${loc.nbIn[0]}</span> | <span style='color:orange;'>${loc.nbIn[1]}</span> | <span style='color:red;'>${loc.nbIn[2]}</span></p>
                <p class='quote' style='color:grey;'>Out  : <span style='color:green;'>${loc.nbOut[0]}</span> | <span style='color:orange;'>${loc.nbOut[1]}</span> | <span style='color:red;'>${loc.nbOut[2]}</span></p>
              </div>`
          });
          // show info bubble
          ui.addBubble(bubble);
        }
      });

      group.addEventListener('tap', function (evt) {

        // Remove existing infoBubbles
        const elements = document.getElementsByClassName('H_ib');
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }

        // event target is the marker itself, group is a parent event target
        // for all objects that it contains
        var position = {
          lat: evt.target.getGeometry().lat,
          lng: evt.target.getGeometry().lng
        };
        var data = evt.target.getData();
        var bubble = new H.ui.InfoBubble(position, {
          // read custom data
          content: `<div class='infoBubble'>
              <h4>${data.name}</h4>
              <p>heures d'ouverture:</p>
              <p class='quote'>lundi: ${data.hours.lundi}</p>
              <p class='quote'>mardi: ${data.hours.mardi}</p>
              <p class='quote'>mercredi: ${data.hours.mercredi}</p>
              <p class='quote'>jeudi: ${data.hours.jeudi}</p>
              <p class='quote'>vendredi: ${data.hours.vendredi}</p>
              <p class='quote'>samedi: ${data.hours.samedi}</p>
              <p class='quote'>dimanche: ${data.hours.dimanche}</p>
            </div>`
        });
        // show info bubble
        ui.addBubble(bubble);

        // Compute coordinates in order to center infobubble
        var decay = map.screenToGeo(
          evt.currentPointer.viewportX - 120,
          evt.currentPointer.viewportY - 145
        );
        map.setCenter(decay, true);
      }, false);

      map.addObject(group);

      var bbox = group.getBoundingBox();
      var p = 0.1;
      var bounds = new H.geo.Rect(bbox.getTop() + 3 * p, bbox.getLeft() - p, bbox.getBottom() - p, bbox.getRight() + p);
      map.getViewModel().setLookAtData({
        bounds
      });

      const elements = document.getElementsByClassName('H_l_horizontal');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }

    }
  ).catch(error => console.log(error));

};