import axios from 'axios';
import { showAlert, showModal } from './alerts.js';

let xsrfToken = localStorage.getItem('xsrfToken');
if (xsrfToken) {
  xsrfToken = JSON.parse(xsrfToken);
  axios.defaults.headers.common['x-xsrf-token'] = xsrfToken;
}

// type is either 'password' or 'data'
export const updateSettings = async (data, type) => {
  try {
    const url =
      type === 'password'
        ? '/api/v1/users/update-password'
        : '/api/v1/users/updateMe';

    const res = await axios({
      method: 'PATCH',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Les données ont été mises à jour avec succès.');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const toggleFavorites = async (relayId) => {
  try {
    const url = `/api/v1/relays/${relayId}/toggleFavorite`;

    const res = await axios({
      method: 'PATCH',
      url
    });

    if (res.data.status === 'success') {
      showAlert('success', res.data.message);
      return true;
    }
  } catch (err) {
    if (err.response.data.message.startsWith('User validation failed'))
      showAlert('error', 'Vous ne pouvez pas ajouter de nouveaux relais à vos favoris. Maximum atteint.');
    else showAlert('error', err.response.data.message);
    return false;
  }
}