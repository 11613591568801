import axios from 'axios';
import { showAlert, showModal } from './alerts.js';

export const sendEmail = async (data) => {
  try {

    console.log(data);
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/sendContact',
      data,
      // headers: {
      //   'Content-Type': 'multipart/form-data'
      // }
    });
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

