import axios from 'axios';
import { showAlert, showModal } from './alerts.js';

// let xsrfToken = localStorage.getItem('xsrfToken');
// if (xsrfToken) {
//   xsrfToken = JSON.parse(xsrfToken);
//   axios.defaults.headers.common['x-xsrf-token'] = xsrfToken;
// }

export const acceptCg = async (data) => {
  try {
    const path = (data.cg.type === 'cgu') ? 'accept-cgu' : (data.cg.type === 'cgv') ? data.id + '/accept-cgv' : (data.cg.type === 'cga-store') ? data.id + '/accept-cga-store' : '/accept-cga-carrier';
    const url = `/api/v1/conditions/${path}`;

    const res = await axios({
      method: 'PATCH',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', res.data.message);
      window.setTimeout(() => {
        const redirect = (data.cg.type === 'cgu') ? '/connexion' : '/tableau-de-bord';
        location.assign(redirect);
      }, 2000);
    } else showAlert('error', res.data.message);
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

