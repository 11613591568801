import axios from 'axios';
import { showAlert, showModal } from './alerts.js';
import { delay } from './utils.js';
// import { payPacket } from './payment.js';

let xsrfToken = localStorage.getItem('xsrfToken');
if (xsrfToken) {
  xsrfToken = JSON.parse(xsrfToken);
  axios.defaults.headers.common['x-xsrf-token'] = xsrfToken;
}

export const getPacket = async (id) => {
  try {
    const url = `/api/v1/packets/${id}`;

    const res = await axios({
      method: 'GET',
      url
    });

    if (res.data.status === 'success') {
      return res.data.data.data;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const getPacketPaths = async (data) => {
  try {
    const url = `/api/v1/paths/get-packet-paths`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      return res.data.data.data;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createPacket = async (data) => {
  try {
    const url = `/api/v1/packets`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      window.location.href = `/paiement/${res.data.data.data._id}`;
    } else {
      showAlert('error', 'Un problème est survenu.');
      await delay(3000);
      window.location.reload();
    }
  } catch (err) {
    showAlert('error', err);
  }
};

export const updatePacketSize = async (data, id) => {
  try {
    const url = `/api/v1/packets/${id}/update-size`;

    const res = await axios({
      method: 'PATCH',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Colis modifié avec succès.');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const validatePacket = async (id) => {
  try {
    const url = `/api/v1/packets/${id}/validate`;

    const res = await axios({
      method: 'PATCH',
      url
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Colis validé avec succès.');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const checkInPacket = async (id) => {
  try {
    const url = `/api/v1/packets/${id}/checkin`;

    const res = await axios({
      method: 'PATCH',
      url
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Colis enregistré avec succès.');
      await delay(3000);
      window.location.reload();
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const checkOutPacket = async (values) => {
  try {
    const url = `/api/v1/packets/${values[0]}/checkout`;
    const res = await axios({
      method: 'PATCH',
      url,
      data: {
        code: values[1]
      }
    });

    if (res.data.status === 'success') {
      showAlert(res.data.data.message.type, res.data.data.message.text);
      await delay(2000);
      // window.location.reload();
      window.location.href = '/tableau-de-bord';
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    await delay(2000);
    window.location.reload();
  }
};
