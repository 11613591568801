/* eslint-disable */
import axios from 'axios';
import { showAlert, showModal } from './alerts.js';
// const stripe = Stripe('pk_test_BUkd0ZXAj6m0q0jMyRgBxNns00PPtgvjjr');

let xsrfToken = localStorage.getItem('xsrfToken');
if (xsrfToken) {
  xsrfToken = JSON.parse(xsrfToken);
  axios.defaults.headers.common['x-xsrf-token'] = xsrfToken;
}

export const pay = async (data) => {
  try {
    const url = `/paiement`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      window.location.href = res.data.data.link;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const payPacket = async (id) => {
  try {
    const url = `/paiement/${id}`;

    const res = await axios({
      method: 'GET',
      url
    });

    if (res.data.status === 'success') {
      // console.log(JSON.stringify(res.data.session.url));
      // console.log(JSON.stringify(res.data.clientSecret));
      return res.data.clientSecret;
      // window.location.href = res.data.data.link;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createPaymentIntent = async (id) => {
  try {
    const url = `/api/v1/payments/create-payment-intent/${id}`;

    const res = await axios({
      method: 'GET',
      url
    });

    if (res.data.status) {

      return res.data;
    }
    // if (res.data.status === 'success') {
    //   // console.log(JSON.stringify(res.data.session.url));
    //   // console.log(JSON.stringify(res.data.clientSecret));
    //   return res.data.clientSecret;
    //   // window.location.href = res.data.data.link;
    // }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createStripeAccount = async (relayId = null) => {
  try {
    const url = `/api/v1/payments/create-stripe-account`;
    let data = {};
    if (relayId) data.relayId = relayId;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') window.location.href = res.data.data.link;
    else showAlert('error', res.data.message);
  } catch (err) {
    showAlert('error', err);
  }
};

export const createStripePayout = async () => {
  try {
    const url = `/api/v1/payments/create-stripe-payout`;

    const res = await axios({
      method: 'GET',
      url
    });

    if (res.data.status === 'success') window.location.reload();
    else showAlert('error', res.data.message);
  } catch (err) {
    showAlert('error', err);
  }
};

export const getStripeTransactions = async (transactionId) => {
  try {
    const url = `/api/v1/payments/get-next-transactions`;
    let data = {};
    if (transactionId) data.transactionId = transactionId;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') return res.data.nextTransactions;
    else showAlert('error', res.data.message);
  } catch (err) {
    showAlert('error', err);
  }
};

export const getStripePublicKey = async () => {
  try {
    const url = `/api/v1/payments/get-stripe-public-key`;

    const res = await axios({
      method: 'GET',
      url
    });

    if (res.data.status) {
      return res.data.pk;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const getPaidPacketLabel = async (packetId, stripePiId) => {
  try {
    const url = `/api/v1/payments/${packetId}/${stripePiId}/label`;

    const res = await axios({
      method: 'PATCH',
      url
    });

    if (res.data.status === 'success') {
      return res.data.status;
    } else showAlert('error', 'Une erreur s\'est produite.');
  } catch (err) {
    showAlert('error', 'Une erreur s\'est produite.');
  }
};

export const changePlan = async (data) => {
  try {
    const url = `/api/v1/payments/change-plan`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    showAlert((res.data.status === 'success') ? 'success' : 'error', res.data.message);
    setTimeout(function () { window.location.reload(); }, 2500);
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
