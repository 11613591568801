import axios from 'axios';
import { showAlert, showModal } from './alerts.js';

let xsrfToken = localStorage.getItem('xsrfToken');
if (xsrfToken) {
  xsrfToken = JSON.parse(xsrfToken);
  axios.defaults.headers.common['x-xsrf-token'] = xsrfToken;
}

export const simulatePackets = async (data) => {
  try {
    const url = `/api/v1/packets/simulate-packets`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', res.data.data.message);
    }
  } catch (err) {
    showAlert('error', err.response);
  }
};

export const simulateDeliveries = async (data) => {
  try {
    const url = `/api/v1/deliveries/simulate-deliveries`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', res.data.data.message);
    }
  } catch (err) {
    showAlert('error', err.response);
  }
};

export const generateOrders = async (data) => {
  try {
    const url = `/api/v1/payments/create-orders`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      let infos = 'Nouvelles Commandes: ' + res.data.countNewOrders +
        '</br>Nouvelles Factures: ' + res.data.countNewInvoices +
        '</br>Commandes modifiées: ' + res.data.countModifiedOrders +
        '</br>Commandes déjà facturées: ' + res.data.invoicedOrders;
      showAlert('success', infos);
    }
  } catch (err) {
    showAlert('error', err.response);
  }
};

export const generatePurchases = async (data) => {
  try {
    const url = `/api/v1/payments/create-purchases`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      let infos = 'Nouvelles Commandes Relais: ' + res.data.countNewRelayOrders +
        '</br>Nouvelles Factures Relais: ' + res.data.countNewRelayInvoices +
        '</br>Commandes modifiées Relais: ' + res.data.countModifiedRelayOrders +
        '</br>Commandes déjà facturées Relais: ' + res.data.invoicedRelayOrders +
        '</br>Nouvelles Commandes Livreurs: ' + res.data.countNewCarrierOrders +
        '</br>Nouvelles Factures Livreurs: ' + res.data.countNewCarrierInvoices +
        '</br>Commandes modifiées Livreurs: ' + res.data.countModifiedCarrierOrders +
        '</br>Commandes déjà facturées Livreurs: ' + res.data.invoicedCarrierOrders;
      showAlert('success', infos);
    }
  } catch (err) {
    showAlert('error', err.response);
  }
};

export const createPayments = async (data) => {
  try {
    const url = `/api/v1/payments/create-payments`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      let infos = 'ok';
      // let infos = 'Nouvelles Commandes: ' + res.data.countNewOrders +
      //   '</br>Nouvelles Factures: ' + res.data.countNewInvoices +
      //   '</br>Commandes modifiées: ' + res.data.countModifiedOrders +
      //   '</br>Commandes déjà facturées: ' + res.data.invoicedOrders;
      showAlert('success', res.data.message);
    }
  } catch (err) {
    showAlert('error', err.response);
  }
};

export const computePrices = async () => {
  try {
    const url = `/api/v1/prices/compute-prices`;

    const res = await axios({
      method: 'GET',
      url,
    });

    if (res.data.status === 'success') {
      showAlert('success', res.data.message);
    }
  } catch (err) {
    showAlert('error', err.response);
  }
};

export const getTransactions = async (data) => {
  try {
    const url = `/api/v1/transactions`;

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {

      var results = document.getElementById('results');

      var d = document.createElement('div');
      d.classList.add('flex', 'flex-row')
      results.appendChild(d);
      var s1 = document.createElement('span');
      var s2 = document.createElement('span');
      var s3 = document.createElement('span');
      var s4 = document.createElement('span');
      s1.innerHTML = 'compte';
      s2.innerHTML = 'type';
      s3.innerHTML = 'statut';
      s4.innerHTML = 'montant';
      d.appendChild(s1);
      d.appendChild(s2);
      d.appendChild(s3);
      d.appendChild(s4);
      for (var t of res.data.transactions) {

        var div = document.createElement('div');
        div.classList.add('flex', 'flex-row')
        results.appendChild(div);

        var span1 = document.createElement('span');
        var span2 = document.createElement('span');
        var span3 = document.createElement('span');
        var span4 = document.createElement('span');
        span1.innerHTML = t.payee;
        span2.innerHTML = t.type;
        span3.innerHTML = t.status;
        span4.innerHTML = t.amount;

        div.appendChild(span1);
        div.appendChild(span2);
        div.appendChild(span3);
        div.appendChild(span4);
      }
    } else if (res.data.status === 'error') {
      showAlert('error', res.data.data.message);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const toggleRelays = async (rels, type) => {
  try {
    const url = `/api/v1/relays/toggle-relays`;
    const data = { rels, type };

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', res.data.message);
    }
  } catch (err) {
    showAlert('error', err.response);
  }
};

export const changeParentRels = async (rels, parentRels) => {
  try {
    const url = `/api/v1/relays/change-parent-relays`;
    const data = { rels, parentRels };

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', res.data.message);
    }
  } catch (err) {
    showAlert('error', err.response);
  }
};