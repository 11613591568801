export const generateFormAndSubmit = (action, data, xsrfToken, data2 = null) => {
  // console.log(data, data2);
  var form = document.createElement('form');
  form.method = 'POST';
  form.action = action;
  form.enctype = 'application/x-www-form-urlencoded';
  var input = document.createElement('input');
  var input2 = document.createElement('input');
  input.type = 'hidden';
  input.name = 'data';
  input.value = data;
  input2.type = 'hidden';
  input2.name = '_xsrf';
  input2.value = xsrfToken;
  form.appendChild(input);
  form.appendChild(input2);
  if (data2) {
    let input3 = document.createElement('input');
    input3.type = 'hidden';
    input3.name = 'data2';
    input3.value = data2
    form.appendChild(input3);
  }
  document.body.appendChild(form);
  form.submit();
};

export const minutesToHm = (d) => {
  d = Number(d);
  var h = Math.floor(d / 60);
  var m = Math.floor(d % 60);
  (h < 10) ? h = '0' + h.toString() : h = h.toString();
  (m < 10) ? m = '0' + m.toString() : m = m.toString();
  return h + ':' + m;
};

export const convertTZ = (date, tzString) => {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
};

export const delay = (time) => {
  return new Promise(resolve => setTimeout(resolve, time));
}

export const validFileType = (file, fileTypes) => {
  return fileTypes.includes(file.type);
}

export const checkRequiredFields = (fields) => {
  if (fields.password && fields.passwordConfirm && fields.password.value !== fields.passwordConfirm.value) return false;
  for (var field of fields) {
    if (field.tagName === 'DIV' && field.innerHTML === '') return false;
    if (field.type === 'checkbox' && !field.checked) return false;
    if (field.value === null || field.value === '') return false;
  }
  return true;
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.split(',')[1]);
  reader.onerror = error => reject(error);
});

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const countdown = (targetTime) => {
  // Get the current time
  const currentTime = new Date().getTime();

  // Calculate the remaining time in milliseconds
  const remainingTime = targetTime - currentTime;

  // Check if the countdown has finished
  if (remainingTime <= 0) {
    return {
      over: true,
      message: 'Délai de paiement expiré.'
    }
  } else {
    // Convert milliseconds to minutes and seconds
    const minutes = String(Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
    const seconds = String(Math.floor((remainingTime % (1000 * 60)) / 1000)).padStart(2, '0');

    return {
      over: false,
      message: `Expire dans ${minutes}:${seconds}`
    }
  }
}