import axios from 'axios';
import { showAlert, showModal, hideModal } from './alerts.js';
import { delay } from './utils.js';
import { checkOutPacket } from './packets.js';

let xsrfToken = localStorage.getItem('xsrfToken');
if (xsrfToken) {
  xsrfToken = JSON.parse(xsrfToken);
  axios.defaults.headers.common['x-xsrf-token'] = xsrfToken;
}

var video = document.createElement("video");
var canvasElement = document.getElementById("canvas");
var loadingMessage = document.getElementById("loadingMessage");
// var outputContainer = document.getElementById("output");
// var outputMessage = document.getElementById("outputMessage");
// var outputData = document.getElementById("outputData");

const scan = async (url) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url
    });
    if ((res.data.status = 'success')) {
      // displayCamStream(canvasElement);
      // console.log(res.data);
      var type = res.data.data.message.type;
      var text = res.data.data.message.text;
      if (type === 'error') {
        showAlert('error', text);
        await delay(2000);
        displayCamStream(canvasElement);
      } else if (type === 'success') {
        showAlert('success', text);
      } else if (type === 'checkout') {
        window.location.href = `/mes-retraits/${res.data.data.data}/code`;
      } else {
        showModal(
          type,
          text,
          [
            (type === 'success' || type === 'shipment') ? { function: () => window.location.reload(), text: 'Confirmer' } : '',
            (type === 'success') ? { function: hideModal, text: 'Signaler un problème' } : ''
          ],
          (type === 'checkout') ? {
            inputs: [{
              id: 'id',
              type: 'hidden',
              value: res.data.data.data
            },
            {
              id: 'code',
              type: 'text',
              pattern: '[0-9]{4}',
              placeholder: ' * * * * ',
              required: true
            }],
            submitText: 'Soumettre le code',
            submitAction: checkOutPacket
          } : ''
        );
      }
      // window.location.reload();
    }
  } catch (err) {
    console.log(err);
    showAlert('error', 'Une erreur est survenue ! Veuillez recommencer SVP...');
    await delay(2000);
    displayCamStream(canvasElement);
  }
};

function drawLine(begin, end, color) {
  var canvas = canvasElement.getContext("2d");

  canvas.beginPath();
  canvas.moveTo(begin.x, begin.y);
  canvas.lineTo(end.x, end.y);
  canvas.lineWidth = 4;
  canvas.strokeStyle = color;
  canvas.stroke();
}

// Use facingMode: environment to attemt to get the front camera on phones
export function displayCamStream(canvasElement) {
  navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function (stream) {
    video.srcObject = stream;
    video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
    video.play();
    requestAnimationFrame(tick);
  });
}

async function tick() {
  var canvas = canvasElement.getContext("2d");

  loadingMessage.innerText = "⌛ Chargement video..."
  if (video.readyState === video.HAVE_ENOUGH_DATA) {
    loadingMessage.hidden = true;
    canvasElement.hidden = false;
    // outputContainer.hidden = false;

    canvasElement.height = video.videoHeight;
    canvasElement.width = video.videoWidth;
    canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
    var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
    var code = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: "dontInvert",
    });
    if (code) {
      drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
      drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
      drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
      drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
      // outputMessage.hidden = true;
      // outputData.parentElement.hidden = false;
      // outputData.innerText = code.data;
      // console.log(code.data);
      scan(code.data);
      return;
      // return 'ok';
    } else {
      // outputMessage.hidden = false;
      // outputData.parentElement.hidden = true;
    }
  }
  requestAnimationFrame(tick);
}
