import { showAlert, showModal } from './alerts.js';
import axios from 'axios';

let xsrfToken = localStorage.getItem('xsrfToken');
if (xsrfToken) {
  xsrfToken = JSON.parse(xsrfToken);
  axios.defaults.headers.common['x-xsrf-token'] = xsrfToken;
}

const searchRelays = async (data, searchType) => {
  try {
    const url =
      searchType === 'locAndRad'
        ? `/api/v1/relays/relays-within/${data.radius}/center/${data.latlng[0]},${data.latlng[1]}`
        : `/api/v1/relays/relays-between/${data.latlng1[0]},${data.latlng1[1]}/${data.latlng2[0]},${data.latlng2[1]}`;

    const res = await axios({
      method: 'GET',
      url
    });

    const rels = res.data.data.data;
    // console.log(rels);
    if (res.data.status === 'success' && rels.length === 0) {
      showAlert('error', 'Il n\'y aucun relais dans ce secteur.');
    } else {
      // const relsIds = rels.map(el => el._id);
      // console.log("ok4", relsIds);
      // return relsIds;
      return rels;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const searchDocs = async (data, searchType, type) => {
  try {

    let rels = '';
    if (searchType === 'favRels') {
      rels = data;
    } else {
      rels = await searchRelays(data, searchType);
      if (!rels) {
        return;
      }
    }

    if (rels.length !== 0) {

      const res = await axios({
        method: 'POST',
        url: `/api/v1/${type}/search`,
        data: rels
      })

      if (res.data.status === 'success' && res.data.data.data.length === 0) {
        showAlert('error', 'Aucune livraison n\'est disponible dans ce secteur.');
      } else if (res.data.status === 'success') {
        return res.data.data.data;
      } else {
        showAlert('error', 'Un problème est survenu. Veuillez retenter l\'opération.');
      }
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const checkNewDelivery = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/deliveries/check`,
      data
    });
    if (res.data.status === 'success' && res.data.results === 0) return true;
    else if (res.data.status === 'success' && res.data.results > 0) showAlert('error', 'Vous êtes déjà inscrit sur ce trajet à cette date et ce créneau.');
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createDelivery = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/deliveries`,
      data
    });
    if (res.data.status === 'success') showAlert('success', "Inscription effectuée avec succès.");
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createProDelivery = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/deliveries/create-pro-delivery`,
      data
    });
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const endDeliveryScan = async (delId) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/deliveries/${delId}/endScan`
    });
    if (res.data.status === 'success') window.location.href = '/mes-livraisons';
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const declareMissingPacket = async (delId, packetId) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/deliveries/${delId}/${packetId}/declareMissing`
    });
    if (res.data.status === 'success') window.location.reload();
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};