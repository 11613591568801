import { getRelaysWithin, getRelaysFromIds } from './relays.js';

export const displayMap = (locations, rels = null) => {
  mapboxgl.accessToken =
    'pk.eyJ1IjoianJnb3VyaW5hdCIsImEiOiJja3U0MXk0dWk0YWRtMzJucWFpcnBkdm00In0._CZnGwFAb4tsplp-yh0LQg';

  var map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/jrgourinat/cku431caj0w7717s9api2mgo6',
    scrollZoom: false,
    // dragRotate: false,
    // dragPan: false,
    doubleClickZoom: false,
    touchZoomRotate: false,
    keyboardZoom: false
    // center: [5, 43],
    // zoom: 6,
    // interactive: false
  });

  // Add zoom and rotation controls to the map.
  map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');

  const bounds = new mapboxgl.LngLatBounds();

  locations.forEach(loc => {
    // Create marker
    const el = document.createElement('div');
    el.className = `marker-${loc.color} marker`;

    // Add marker
    new mapboxgl
      .Marker({
        element: el,
        anchor: 'bottom'
      })
      .setLngLat(loc.location.coordinates)
      // .setLngLat([5.5, 45.5])
      .addTo(map);

    // // Add popup
    // if (rels) {
    //   new mapboxgl
    //     .Popup({
    //       offset: 30
    //     })
    //     .setLngLat(loc.location.coordinates)
    //     .setHTML(`<span>${rels[locations.indexOf(loc)].name}</span>`)
    //     .addTo(map);
    // }

    // Extend map bounds to include current location
    bounds.extend(loc.location.coordinates);
  });

  map.fitBounds(bounds, {
    animate: false,
    padding: {
      top: 120,
      bottom: 120,
      left: 60,
      right: 60
    }
  });

  // console.log(map);
  return map;
};

export const displayLocSearch = (searchBoxId, results) => {
  mapboxgl.accessToken =
    'pk.eyJ1IjoianJnb3VyaW5hdCIsImEiOiJja3U0MXk0dWk0YWRtMzJucWFpcnBkdm00In0._CZnGwFAb4tsplp-yh0LQg';

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    countries: 'fr',
    types: 'address,place,postcode,locality,neighborhood'
  });

  geocoder.addTo(searchBoxId);

  // Add geocoder result to container.
  geocoder.on('result', (e) => {
    // results.innerText = JSON.stringify(e.result.center);
    results.innerText = JSON.stringify(e.result);
  });

  // Clear results container when search is cleared.
  // geocoder.on('clear', () => {
  //   results.innerText = '';
  // });

  // geocoder.on('focus', () => {
  //   geocoder.clear();
  // });
};

export const displayLocSearchAndReturnRelaysList = async (searchBoxId, results, map = null) => {
  mapboxgl.accessToken =
    'pk.eyJ1IjoianJnb3VyaW5hdCIsImEiOiJja3U0MXk0dWk0YWRtMzJucWFpcnBkdm00In0._CZnGwFAb4tsplp-yh0LQg';

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    countries: 'fr',
    types: 'address,place,postcode,locality,neighborhood'
  });

  geocoder.addTo(searchBoxId);

  // Add geocoder result to container.
  geocoder.on('result', async (e) => {
    // results.innerText = JSON.stringify(e.result.center);
    var arr = e.result.center;
    var latlng = JSON.stringify(arr.reverse()).slice(1, -1);
    results.innerText = latlng;
    // return await getRelaysWithin(50, latlng);
    // const ids = await getRelaysWithin(30, latlng);
    // const rels = await getRelaysFromIds(ids);
    const rels = await getRelaysWithin(5, latlng);
    let lastChar = searchBoxId[searchBoxId.length - 1];
    const container = document.getElementById(`relsContainer${(lastChar === '1' || lastChar === '2') ? lastChar : ''}`);
    container.innerHTML = '';
    // container.style.overflow = 'hidden';
    const relsHours = JSON.parse(document.getElementById('relsHours').value);
    // console.log(relsHours);

    if (rels.length > 0) {

      // container.style.overflow = 'scroll';
      // var n = 0;
      rels.forEach(e => {

        // n++;
        // var radiobox = document.createElement('input');
        // radiobox.type = 'radio';
        // radiobox.id = 'rel' + n;
        // radiobox.value = e._id;
        // radiobox.name = (lastChar === 1) ? 'startRel' : 'endRel';

        // var label = document.createElement('label')
        // label.htmlFor = 'rel' + n;

        // var span = document.createElement('span');
        // label.appendChild(span);


        // var description = document.createTextNode(e.name);
        // span.appendChild(description);

        // var newline = document.createElement('br');

        // container.appendChild(radiobox);
        // container.appendChild(label);
        // container.appendChild(newline);


        // var card = document.createElement('div');
        // // card.classList.add('card');
        // card.style.margin = '1.5rem 0 0 0';
        // card.style.padding = '0.2rem';
        // card.style.overflow = 'hidden';

        var html = `<div onclick='event.preventDefault(); selectRel(this,${(lastChar === '1') ? 'startRel' : 'endRel'})' data-id='${e._id.toString()}'>`;
        html += `<h6>`;
        html += `<svg class='card__icon' style='width:4rem;'><use xlink:href='/img/icons.svg#icon-map-pin'></use>`;
        html += `</svg>`;
        html += `<span>${e.name}</span></h6>`;
        html += `<div class='flex flex-col' style='justify-content: flex-start;'>`;
        html += `<span style='font-size:1rem;padding:0.2rem 0.2rem 0.2rem 1.2rem;'>${e.location.address}</span>`;
        html += `<button class='dropbtn' onclick='event.preventDefault(); toggleHours(this)' style='padding:2px;font-size:0.9rem;width:90%;margin:0 auto;'>Horaires</button>`;
        html += `<div class='dropdown-content' style='position:relative;display:none;margin-top:0.5rem;'>`;
        // html += JSON.stringify(e.hoursStr);
        if (relsHours[e._id.toString()]) {
          for (let d of Object.keys(relsHours[e._id.toString()])) {
            html += `<div class='flex flex-row center' style='justify-content:flex-start;align-items:baseline;margin:0 0 0 0.5rem ;'>`;
            html += `<span style='width:30%;'>${d}: </span>`;
            html += `<strong>${relsHours[e._id.toString()][d]}</strong>`;
            html += `</br>`;
            html += `</div>`;
          }
        }
        html += `</div>`;
        html += `</div>`;
        html += `</div>`;

        // card.innerHTML = html;

        // container.appendChild(card);

        container.innerHTML += html;
        // var dropBtn = document.querySelectorAll('button .dropbtn');
        // dropBtn.forEach(el => {
        //   el.addEventListener('click', async evt => {
        //     evt.preventDefault();
        //     console.log('blalba');
        //     let content = el.parentNode.querySelector('.dropdown-content');
        //     if (window.getComputedStyle(content).display === "none") {
        //       // let contents = document.querySelectorAll('.dropdown-content');
        //       // contents.forEach(e => { e.style.display = 'none'; });
        //       content.style.display = 'block';
        //     } else {
        //       content.style.display = 'none';
        //     }
        //   });
        // });


        // button.dropbtn(style='padding:4px;') Horaires
        // .dropdown-content(style='top:25px;')
        //   each d in Object.keys(rel.hoursStr)
        //     .flex.flex-row.center(style='justify-content: flex-start;font-size:1.3rem;')
        //       span.w30= `${d}: `
        //       strong= rel.hoursStr[d]

      });

      // var title = document.createElement('label')
      // title.htmlFor = 'paths';
      // title.appendChild(document.createTextNode('Trajets possibles'));
      // title.classList.add('form__label');
      // var paths = document.createElement('div');
      // paths.id = 'paths';
      // paths.innerHTML = '<h3>Renseignez le formulaire puis sélectionnez un relais d\'arrivée pour afficher les trajets possibles</h3>';
      // container.parentNode.appendChild(title);
      // container.parentNode.appendChild(paths);
    } else {
      container.innerHTML = '<h5>Il n\'y a aucun relais disponible dans ce secteur</h5>';
    }
  });
};

// export const clearGeocoder = (geocoder) => {
//   geocoder.clear();
// };