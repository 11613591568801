export const hideAlert = () => {
  const el = document.querySelector('.alert');
  if (el) el.parentElement.removeChild(el);
};

// type is 'success' or 'error'
export const showAlert = (type, msg, time = 3) => {
  hideAlert();
  const markup = `<div class="alert alert--${type}">${msg}</div>`;
  document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
  window.setTimeout(hideAlert, time * 1000);
};

export const showModal = (type, msg, actions, formInputs) => {

  var modalOverlay = document.createElement('div');
  var modal = document.createElement('div');
  var p = document.createElement('p');
  modalOverlay.classList.add('modal--overlay');
  modal.classList.add('modal', 'modal--' + type);
  p.innerHTML = msg;
  modal.appendChild(p);

  if (formInputs && formInputs !== '') {
    var form = document.createElement('form');
    for (let formInput of formInputs.inputs) {
      let input = document.createElement('input');
      input.id = formInput.id;
      input.type = formInput.type;
      if (formInput.pattern) input.pattern = formInput.pattern;
      if (formInput.value) input.value = formInput.value;
      input.placeholder = formInput.placeholder;
      input.required = formInput.required;
      form.appendChild(input);
    }
    var submitBtn = document.createElement('input');
    submitBtn.type = 'submit';
    submitBtn.classList.add('btn', 'btn--small', 'btn--blue');
    submitBtn.style = 'margin-left:0;'
    submitBtn.innerHTML = formInputs.submitText;
    form.appendChild(submitBtn);

    // console.log("ok");
    form.addEventListener('submit', e => {
      e.preventDefault();
      var values = [];
      for (let formInput of formInputs.inputs) {
        var val = document.getElementById(formInput.id).value;
        var pa = formInput.pattern;
        if (pa) {
          pa = `^${pa}$`;
          pa.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
          var expression = new RegExp(pa);
          if (expression.test(val)) values.push(val);
        } else values.push(val);
      }
      formInputs.submitAction(values);
    });
    modal.appendChild(form);

  }

  for (let action of actions) {
    if (action !== '') {
      let actionBtn = document.createElement('button');
      actionBtn.classList.add('btn', 'btn--small', 'btn--blue');
      actionBtn.style = 'margin-left:1rem;'
      actionBtn.innerHTML = action.text;
      actionBtn.addEventListener('click', e => {
        action.function();
      });

      modal.appendChild(actionBtn);
    }
  }

  modalOverlay.appendChild(modal);
  document.body.appendChild(modalOverlay);
};

export const hideModal = () => {
  const el = document.querySelector('.modal--overlay');
  if (el) el.parentElement.removeChild(el);
};